import axios from 'axios';
import { extractDataFromResponse, parseApiErrorResponse } from 'utils/apiUtils';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const refreshTokenRequest = async ({ refreshToken }) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/admin/auth/refresh-tokens`, { refreshToken });
        return extractDataFromResponse({
            response,
            showErrorToast: false,
            showSuccessToast: false
        });
    } catch (error) {
        return parseApiErrorResponse({
            error,
            showToast: false
        });
    }
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, TableCell, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from 'appUrls';
import useFetchSelectOptions from 'hooks/useFetchSelectOptions';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import { useSelector } from 'react-redux';
import { selectIsServiceActive } from 'store/slices/serviceSlice';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import TextField from 'libs/formik_fields/TextField';
import SelectField from 'libs/formik_fields/SelectField';
import SwitchField from 'libs/formik_fields/CheckBoxField';
import {
    getActiveUsersListForJobCardProcess,
    getVehicleModelRateInfoById,
    jobCardCloseWithoutBillReason,
    jobCardReasonForNotBalancingOptions,
    jobCardN2Types,
    jobCardTyreRepairTypes,
    jobCardWheelTypes
} from 'services/job_card';
import appServices from 'appServices';
import { useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import useModalState from 'hooks/useModalState';
import { getActiveTyreListRequest } from '../../services/tyre';
import { getActiveTyreSizeListRequest } from '../../services/tyre_size';

const emptyJobCard = {
    jobCardNo: null,
    customer: {
        name: '',
        email: '',
        mobile: '',

        address: '',
        gstin: '',
        vehicleRegNo: '',
        vehicleDTO: null,
        vehicleModelDTO: null
    },
    odoMeterReading: null,
    remark: '',
    tyreTubeFitment: {
        enable: false
    },
    wheelAlignment: {
        enable: false
    },
    n2: {
        enable: false
    },
    tyreRepair: {
        enable: true
    },
    wheelBalancing: {
        enable: false
    },
    alignmentFOC: false,
    wheelBalancingWeight: null,
    otherProductSales: null,
    newTyreFitmentDTO: null,
    wheelTypeDTO: null,
    jobCardStatusDTO: null,
    jobCardCreateDateTime: moment().format('DD-MM-YYYY hh:mm A')
};

const jobCardProcessSchema = yup.object().shape({
    address: yup.string('Please enter valid description.'),
    customer: yup.object().shape({
        name: yup.string().required('Please enter name of customer.'),
        address: yup.string('Please enter valid description.'),
        email: yup.string('Please enter valid email.'),
        gstin: yup.string('Please enter valid gstin.'),
        mobile: yup.string('Please enter valid mobile.'),
        vehicleRegNo: yup.mixed().required('Please enter vehicle registration number.'),
        vehicleDTO: yup.mixed().required('Vehicle is required.'),
        vehicleModelDTO: yup.mixed().required('Vehicle model is required.')
    }),
    remark: yup.string('Please enter valid remarks.').nullable(),
    // operator: yup.mixed().required('Please select operator.'),
    // wheelTypeDTO: yup.mixed().required('Please select wheel type.'),
    odoMeterReading: yup.number().required('Please enter odometer reading.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    centerVertical: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[900]}`
}));

const ProcessJobCardFormContent = ({ isSubmitting, setFieldValue, values, errors, submitForm }) => {
    const [jobCardPayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.JOB_CARD });
    const isTyreTubeFitmentServiceEnabled = useSelector(selectIsServiceActive(appServices.TYRE_TUBE_FITMENT));
    const isWheelBalancingServiceEnabled = useSelector(selectIsServiceActive(appServices.WHEEL_BALANCING));
    const isWheelAlignmentServiceEnabled = useSelector(selectIsServiceActive(appServices.WHEEL_ALIGNMENT));
    const isN2ServiceEnabled = useSelector(selectIsServiceActive(appServices.N2));
    const isTyreRepairServiceEnabled = useSelector(selectIsServiceActive(appServices.TYRE_REPAIR));
    const isOtherProductSalesServiceEnabled = useSelector(selectIsServiceActive(appServices.OTHER_PRODUCT_SALES));

    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(appUrls.JOB_CARD);
    }, [navigate]);

    const classes = useStyles();

    const { isOpen, handleClose, handleOpen } = useModalState(false);

    const { options: activeOperatorsOptions, loading: activeOperatorsOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = jobCardPayloadCreator({});
            return getActiveUsersListForJobCardProcess(payload);
        }
    });

    const { options: activeTyreOptions, loading: activeTyreOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = jobCardPayloadCreator({});
            return getActiveTyreListRequest(payload);
        }
    });

    const { options: activeTyreSizeOptions, loading: activeTyreSizeOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = jobCardPayloadCreator({});
            return getActiveTyreSizeListRequest(payload);
        }
    });

    const [vehicleModalRates, setVehicleModalRates] = useState(null);
    const [vehicleModalRatesLoading, setVehicleModalRatesLoading] = useState(false);

    const vehicleModelId = useMemo(() => values?.customer?.vehicleModelDTO?.id, [values?.customer?.vehicleModelDTO?.id]);

    const selectedWheelTypeCode = useMemo(() => values?.wheelTypeDTO?.code, [values?.wheelTypeDTO]);
    const hasValidTyreFitment = useMemo(() => {
        const newTyreFitmentDTO = values?.newTyreFitmentDTO || {};

        return (
            newTyreFitmentDTO?.tyre?.id &&
            newTyreFitmentDTO?.tyre?.name &&
            newTyreFitmentDTO?.size?.id &&
            newTyreFitmentDTO?.size?.size &&
            newTyreFitmentDTO?.qty > 0 &&
            newTyreFitmentDTO?.serialNumbers &&
            newTyreFitmentDTO?.serialNumbers?.length > 0
        );
    }, [values?.newTyreFitmentDTO]);

    useEffect(() => {
        async function fetchVehicleModalRates() {
            setVehicleModalRatesLoading(true);
            const payload = jobCardPayloadCreator({ id: vehicleModelId });
            const result = await getVehicleModelRateInfoById(payload);
            if (result.error === false) {
                const ratesMap = {};
                result.forEach((rate) => {
                    const obj = rate;
                    if (obj.subComponentExist) {
                        const subComponentMap = {};
                        obj.subComponent.forEach((subComponent) => {
                            subComponentMap[subComponent.code] = subComponent;
                        });
                        obj.subComponent = subComponentMap;
                    }
                    ratesMap[rate.code] = rate;
                });
                setVehicleModalRates(ratesMap);
            }
            setVehicleModalRatesLoading(false);
        }

        if (vehicleModelId) {
            fetchVehicleModalRates();
        }
    }, [vehicleModelId]);

    // set Tyre/Tube Fitment initial Amount
    useEffect(() => {
        console.log('UseEffect: set Tyre/Tube Fitment initial Amount');
        const tyreTubeFitmentEnabled = values?.tyreTubeFitment?.enable;
        const initialTyreTubeFitmentAmount = Number(vehicleModalRates?.tyre_tube_fitment?.value ?? 0);
        if (tyreTubeFitmentEnabled) {
            if (Number(values?.tyreTubeFitment?.amount ?? 0) === 0) {
                setFieldValue('tyreTubeFitment.amount', initialTyreTubeFitmentAmount);
            }
        } else {
            setFieldValue('tyreTubeFitment.amount', 0);
        }
    }, [values?.tyreTubeFitment?.enable, vehicleModalRates]);

    // set Wheel Balancing initial Amount
    useUpdateEffect(() => {
        console.log('UseEffect: set Wheel Balancing initial Amount');
        const wheelBalancingEnabled = !!values?.wheelBalancing?.enable;
        setFieldValue('wheelBalancingWeight.lf', wheelBalancingEnabled);
        setFieldValue('wheelBalancingWeight.rf', wheelBalancingEnabled);
        setFieldValue('wheelBalancingWeight.lr', wheelBalancingEnabled);
        setFieldValue('wheelBalancingWeight.rr', wheelBalancingEnabled);
        setFieldValue('wheelBalancingWeight.st', wheelBalancingEnabled);
    }, [values?.wheelBalancing?.enable]);

    // set Wheel Balancing initial Amount
    useUpdateEffect(() => {
        console.log('UseEffect: set Wheel Balancing initial Amount');
        const wheelBalancingEnabled = values?.wheelBalancing?.enable;

        if (wheelBalancingEnabled) {
            if (selectedWheelTypeCode) {
                const initialWheelBalancingAmount = Number(
                    vehicleModalRates?.wheel_balancing?.subComponent?.[selectedWheelTypeCode]?.value ?? 0
                );
                const initialWheelBalancingWeightRate = Number(
                    vehicleModalRates?.wheel_balancing_weight?.subComponent?.[selectedWheelTypeCode]?.value ?? 0
                );

                let wheelAheelAmount = 0;
                const isLf = values?.wheelBalancingWeight?.lf;
                if (isLf) {
                    wheelAheelAmount += initialWheelBalancingAmount;
                }
                const isRf = values?.wheelBalancingWeight?.rf;
                if (isRf) {
                    wheelAheelAmount += initialWheelBalancingAmount;
                }
                const isLr = values?.wheelBalancingWeight?.lr;
                if (isLr) {
                    wheelAheelAmount += initialWheelBalancingAmount;
                }
                const isRr = values?.wheelBalancingWeight?.rr;
                if (isRr) {
                    wheelAheelAmount += initialWheelBalancingAmount;
                }
                const isSt = values?.wheelBalancingWeight?.st;
                if (isSt) {
                    wheelAheelAmount += initialWheelBalancingAmount;
                }

                setFieldValue('wheelBalancing.amount', wheelAheelAmount);
                setFieldValue('wheelBalancingWeight.rate', initialWheelBalancingWeightRate);
            } else {
                setFieldValue('wheelBalancing.amount', 0);
            }
        } else {
            setFieldValue('wheelBalancing.amount', 0);
        }
    }, [
        values?.wheelBalancingWeight?.lf,
        values?.wheelBalancingWeight?.rf,
        values?.wheelBalancingWeight?.lr,
        values?.wheelBalancingWeight?.rr,
        values?.wheelBalancingWeight?.st,
        values?.wheelBalancing?.enable,
        selectedWheelTypeCode,
        vehicleModalRates
    ]);

    // reset lf Weight Balance Qty
    useEffect(() => {
        console.log('UseEffect: reset lf Weight Balance Qty');
        const isLf = values?.wheelBalancingWeight?.lf;
        if (!isLf) {
            setFieldValue('wheelBalancingWeight.lfQty', 0);
        }
    }, [values?.wheelBalancingWeight?.lf]);

    // reset lr Weight Balance Qty
    useEffect(() => {
        console.log('UseEffect: reset lr Weight Balance Qty');
        const isLr = values?.wheelBalancingWeight?.lr;
        if (!isLr) {
            setFieldValue('wheelBalancingWeight.lrQty', 0);
        }
    }, [values?.wheelBalancingWeight?.lr]);

    // Add Discount in Wheel alignment amount
    useEffect(() => {
        const alignmentFOC = values?.alignmentFOC;
        if (alignmentFOC && values?.wheelAlignment.enable) {
            setFieldValue('otherProductSales[0].product', 'Alignment FOC With Tyres');
            setFieldValue('otherProductSales[0].amount', -1 * values?.wheelAlignment.amount);
        } else {
            setFieldValue('otherProductSales[0].product', '');
            setFieldValue('otherProductSales[0].amount', '');
        }
    }, [values?.alignmentFOC]);

    // reset rf Weight Balance Qty
    useEffect(() => {
        console.log('UseEffect: reset rf Weight Balance Qty');
        const isRf = values?.wheelBalancingWeight?.rf;
        if (!isRf) {
            setFieldValue('wheelBalancingWeight.rfQty', 0);
        }
    }, [values?.wheelBalancingWeight?.rf]);

    // reset rr Weight Balance Qty
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        console.log('UseEffect: reset rr Weight Balance Qty');
        const isRr = values?.wheelBalancingWeight?.rr;
        if (!isRr) {
            setFieldValue('wheelBalancingWeight.rrQty', 0);
        }
    }, [values?.wheelBalancingWeight?.rr]);

    // reset st Weight Balance Qty
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        console.log('UseEffect: reset st Weight Balance Qty');
        const isSt = values?.wheelBalancingWeight?.st;
        if (!isSt) {
            setFieldValue('wheelBalancingWeight.stQty', 0);
        }
    }, [values?.wheelBalancingWeight?.st]);

    // calculate weight balancing weight total qty
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        console.log('UseEffect: calculate weight balancing weight total qty');
        const lfQty = Number(values?.wheelBalancingWeight?.lfQty ?? 0);
        const rfQty = Number(values?.wheelBalancingWeight?.rfQty ?? 0);
        const lrQty = Number(values?.wheelBalancingWeight?.lrQty ?? 0);
        const rrQty = Number(values?.wheelBalancingWeight?.rrQty ?? 0);
        const stQty = Number(values?.wheelBalancingWeight?.stQty ?? 0);
        const totalQty = lfQty + rfQty + lrQty + rrQty + stQty;
        setFieldValue('wheelBalancingWeight.totalQty', totalQty);
    }, [values?.wheelBalancingWeight]);

    // calculate weight balancing weight total amount according to  total qty and rate
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUpdateEffect(() => {
        console.log('UseEffect: calculate weight balancing weight total amount according to  total qty and rate');
        const qty = Number(values?.wheelBalancingWeight?.totalQty ?? 0);
        const rate = Number(values?.wheelBalancingWeight?.rate ?? 0);
        const amount = qty * rate;
        setFieldValue('wheelBalancingWeight.amount', amount);
    }, [values?.wheelBalancingWeight?.totalQty, values?.wheelBalancingWeight?.rate, vehicleModalRates]);

    // set Wheel Alignment initial Amount
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        console.log('UseEffect: set Wheel Alignment initial Amount');
        const wheelAlignmentEnabled = values?.wheelAlignment?.enable;
        const initialWheelAlignmentAmount = Number(vehicleModalRates?.wheel_alignment?.value ?? 0);
        if (wheelAlignmentEnabled) {
            if (Number(values?.wheelAlignment?.amount ?? 0) === 0) {
                setFieldValue('wheelAlignment.amount', initialWheelAlignmentAmount);
            }
        } else {
            setFieldValue('wheelAlignment.amount', 0);
            setFieldValue('alignmentFOC', false);
        }
    }, [values?.wheelAlignment?.enable, vehicleModalRates]);

    // set N2 initial Rate
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        console.log('UseEffect: set N2 initial Rate');
        const n2Enabled = values?.n2?.enable;
        const selectedN2TypeCode = values?.n2?.n2Type?.code;
        if (n2Enabled && selectedN2TypeCode) {
            const initialN2Rate = Number(vehicleModalRates?.n_2?.subComponent?.[selectedN2TypeCode]?.value ?? 0);
            // if (Number(values?.n2?.rate ?? 0) === 0) {
            setFieldValue('n2.rate', initialN2Rate);
            // }
        } else {
            setFieldValue('n2.rate', 0);
        }
    }, [values?.n2?.enable, values?.n2?.n2Type?.code, vehicleModalRates]);

    // calculate n2 amount according to qty and rate
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUpdateEffect(() => {
        console.log('UseEffect: calculate n2 amount according to qty and rate');
        const qty = Number(values?.n2?.qty ?? 0);
        const rate = Number(values?.n2?.rate ?? 0);
        const amount = qty * rate;
        setFieldValue('n2.amount', amount);
    }, [values?.n2?.qty, values?.n2?.rate, vehicleModalRates]);

    // set Tyre Repair initial Rate
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        console.log('UseEffect: set Tyre Repair initial Rate');
        const tyreRepairEnabled = values?.tyreRepair?.enable;
        const selectedTyreRepairType = values?.tyreRepair?.repairTypeDTO?.code;
        if (tyreRepairEnabled && selectedTyreRepairType) {
            const initialTyreRepairRate = Number(vehicleModalRates?.tyre_repair?.subComponent?.[selectedTyreRepairType]?.value ?? 0);
            // if (Number(values?.tyreRepair?.rate ?? 0) === 0) {
            setFieldValue('tyreRepair.rate', initialTyreRepairRate);
            // }
        } else {
            setFieldValue('tyreRepair.rate', 0);
        }
    }, [values?.tyreRepair?.enable, values?.tyreRepair?.repairTypeDTO?.code, vehicleModalRates]);

    // calculate tyre repair amount according to qty and rate
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUpdateEffect(() => {
        console.log('UseEffect: calculate tyre repair amount according to qty and rate');
        const qty = Number(values?.tyreRepair?.qty ?? 0);
        const rate = Number(values?.tyreRepair?.rate ?? 0);
        const amount = qty * rate;
        setFieldValue('tyreRepair.amount', amount);
    }, [values?.tyreRepair?.qty, values?.tyreRepair?.rate, vehicleModalRates]);

    return (
        <>
            <Grid container>
                {/* <Grid item xs={12}> */}
                {/*    <pre> {JSON.stringify(values, null, 2)}</pre> */}
                {/* </Grid> */}
                <Grid item xs={12} pb={1}>
                    <Typography align="center" variant="h2">
                        Process Job Card ({values?.jobCardNo})
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={6} className={classes.centerVertical}>
                            <Typography sx={{ display: 'inline-block' }} variant="body1">
                                Name &amp; Address:
                            </Typography>
                            <Typography sx={{ display: 'inline-block' }} variant="body1" noWrap>
                                {values.customer.name}, {values.customer.address}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.centerVertical}>
                            <Typography flex={1} variant="body1">
                                Contact No. #: {values?.customer?.mobile}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.centerVertical}>
                            <Typography flex={1} variant="body1">
                                In Time. #: {moment().format('DD-MM-YYYY HH:mm')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.centerVertical}>
                            <Typography variant="body1">Registration #: {values?.customer?.vehicleRegNo}</Typography>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.centerVertical}>
                            <Typography variant="body1">
                                Make #: {values?.customer?.vehicleDTO?.name} {values?.customer?.vehicleModelDTO?.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mb: 0.5 }} variant="body1">
                                Last Odometer Reading: {values.lastOdoMeterReading ?? 0}
                            </Typography>
                            <TextField type="number" name="odoMeterReading" label="Odometer Reading" showErrorText={false} narrow />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <SelectField
                                narrow
                                label="Wheel Type"
                                name="wheelTypeDTO"
                                placeholder="Select wheel type"
                                showErrorText={false}
                                labelKey="value"
                                valueKey="id"
                                options={jobCardWheelTypes}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {isTyreTubeFitmentServiceEnabled && (
                            <Grid item xs={12} md={2}>
                                <Grid container>
                                    <Grid xs={12}>
                                        <SwitchField name="tyreTubeFitment.enable" label="Tyre/Tube Fitment" />
                                    </Grid>
                                    <Grid xs={12} pt={1}>
                                        <TextField
                                            type="number"
                                            disabled={!values?.tyreTubeFitment?.enable}
                                            name="tyreTubeFitment.amount"
                                            label="Amt"
                                            showErrorText={false}
                                            narrow
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {isWheelBalancingServiceEnabled && (
                            <Grid item xs={12} md={2}>
                                <Grid container>
                                    <Grid xs={12}>
                                        <SwitchField name="wheelBalancing.enable" label="Wheel Balancing" />
                                    </Grid>
                                    <Grid xs={12} pt={1}>
                                        <TextField
                                            disabled={!values?.wheelBalancing.enable}
                                            type="number"
                                            name="wheelBalancing.amount"
                                            label="Amt"
                                            showErrorText={false}
                                            narrow
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {isWheelAlignmentServiceEnabled && (
                            <Grid item xs={12} md={2}>
                                <Grid container>
                                    <Grid xs={12}>
                                        <SwitchField name="wheelAlignment.enable" label="Wheel Alignment" />
                                    </Grid>
                                    <Grid xs={12} pt={1}>
                                        <TextField
                                            disabled={!values?.wheelAlignment?.enable}
                                            type="number"
                                            narrow
                                            name="wheelAlignment.amount"
                                            label="Amt"
                                            showErrorText={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {isN2ServiceEnabled && (
                            <Grid item xs={12} md={3}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <SwitchField label="n2" name="n2.enable" />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SelectField
                                            isDisabled={!values?.n2?.enable}
                                            labelKey="value"
                                            valueKey="id"
                                            narrow
                                            name="n2.n2Type"
                                            options={jobCardN2Types}
                                            showErrorText={false}
                                        />
                                    </Grid>
                                    <Grid item xs={4} pt={1}>
                                        <TextField type="number" disabled={!values?.n2?.enable} narrow name="n2.qty" label="Qty" />
                                    </Grid>
                                    <Grid item xs={4} pt={1}>
                                        <TextField
                                            type="number"
                                            disabled={!values?.n2?.enable}
                                            narrow
                                            name="n2.rate"
                                            label="Rate"
                                            sx={{ px: 1 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} pt={1}>
                                        <TextField type="number" disabled={!values?.n2?.enable} narrow name="n2.amount" label="Amount" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {isTyreRepairServiceEnabled && (
                            <Grid item xs={12} md={3}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <SwitchField label="Tyre Repair" name="tyreRepair.enable" />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <SelectField
                                            narrow
                                            name="tyreRepair.repairTypeDTO"
                                            options={jobCardTyreRepairTypes}
                                            labelKey="value"
                                            valueKey="id"
                                            showErrorText={false}
                                            isDisabled={!values?.tyreRepair?.enable}
                                        />
                                    </Grid>
                                    <Grid item xs={4} pt={1}>
                                        <TextField
                                            type="number"
                                            narrow
                                            name="tyreRepair.qty"
                                            label="Qty"
                                            disabled={!values?.tyreRepair?.enable}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sx={{ px: 1 }} pt={1}>
                                        <TextField
                                            type="number"
                                            narrow
                                            name="tyreRepair.rate"
                                            label="Rate"
                                            disabled={!values?.tyreRepair?.enable}
                                        />
                                    </Grid>
                                    <Grid item xs={4} pt={1}>
                                        <TextField
                                            type="number"
                                            narrow
                                            name="tyreRepair.amount"
                                            label="Amount"
                                            disabled={!values?.tyreRepair?.enable}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {isOtherProductSalesServiceEnabled && (
                    <Grid item xs={12} p={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography variant="h3">Please add amount and description of other items</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SwitchField
                                    disabled={!values?.wheelAlignment.enable}
                                    name="alignmentFOC"
                                    label="Alignment FOC With Tyres"
                                    showErrorText={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={1} className={classes.centerVertical}>
                                        <Typography variant="h4">1.</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            narrow
                                            disabled={values?.alignmentFOC}
                                            name="otherProductSales[0].product"
                                            // label="Description"
                                            showErrorText={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            narrow
                                            type="number"
                                            name="otherProductSales[0].amount"
                                            // label="Amount"
                                            showErrorText={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={1} className={classes.centerVertical}>
                                        <Typography variant="h4">2.</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField narrow name="otherProductSales[1].product" showErrorText={false} />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            narrow
                                            type="number"
                                            name="otherProductSales[1].amount"
                                            // label="Amount"
                                            showErrorText={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={1} className={classes.centerVertical}>
                                        <Typography variant="h4">3.</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField narrow name="otherProductSales[2].product" showErrorText={false} />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            narrow
                                            type="number"
                                            name="otherProductSales[2].amount"
                                            // label="Amount"
                                            showErrorText={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={1} className={classes.centerVertical}>
                                        <Typography variant="h4">4.</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField narrow name="otherProductSales[3].product" showErrorText={false} />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            narrow
                                            type="number"
                                            name="otherProductSales[3].amount"
                                            // label="Amount"
                                            showErrorText={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            flexDirection: {
                                xs: 'column-reverse',
                                sm: 'column-reverse',
                                md: 'row',
                                lg: 'row',
                                xl: 'row'
                            }
                        }}
                    >
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={12} pt={2}>
                                    <Typography variant="h3">Reason(s) of not alignment</Typography>
                                </Grid>
                                {values.closewithoutSaleReasons.map((reason, index) => (
                                    <Grid item xs={12} key={reason.reason}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={1}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Typography variant="h4">{reason.reason}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <SwitchField
                                                    showErrorText={false}
                                                    name={`closewithoutSaleReasons['${index}'].enable`}
                                                    label={reason.label}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField
                                                    narrow
                                                    showErrorText={false}
                                                    label="Reason"
                                                    name={`closewithoutSaleReasons['${index}'].text`}
                                                    disabled={!values.closewithoutSaleReasons[index].enable}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={12} pt={4}>
                                    <TextField narrow name="remark" label="Remarks" rows={3} multiline showErrorText={false} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <SwitchField
                                                name="notOrderedForBalance"
                                                label="Not ordered for balance"
                                                showErrorText={false}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button color="info" size="small" variant="text" onClick={handleOpen}>
                                                {hasValidTyreFitment ? 'Edit' : 'Add'} Tyre Fitment
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <SwitchField
                                                        disabled={!values?.wheelBalancing?.enable}
                                                        name="wheelBalancingWeight.lf"
                                                        label="LF"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        narrow
                                                        disabled={!values?.wheelBalancing?.enable || !values?.wheelBalancingWeight?.lf}
                                                        label="Qty"
                                                        name="wheelBalancingWeight.lfQty"
                                                        type="number"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <SelectField
                                                        narrow
                                                        name="wheelBalancingWeight.lfReason"
                                                        placeholder="Reason for not balancing"
                                                        options={jobCardReasonForNotBalancingOptions}
                                                        showErrorText={false}
                                                        labelKey="value"
                                                        valueKey="id"
                                                        isDisabled={values?.wheelBalancingWeight?.lf}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <SwitchField
                                                        disabled={!values?.wheelBalancing?.enable}
                                                        name="wheelBalancingWeight.rf"
                                                        label="RF"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        narrow
                                                        disabled={!values?.wheelBalancing?.enable || !values?.wheelBalancingWeight?.rf}
                                                        label="Qty"
                                                        name="wheelBalancingWeight.rfQty"
                                                        type="number"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <SelectField
                                                        narrow
                                                        name="wheelBalancingWeight.rfReason"
                                                        placeholder="Reason for not balancing"
                                                        options={jobCardReasonForNotBalancingOptions}
                                                        showErrorText={false}
                                                        labelKey="value"
                                                        valueKey="id"
                                                        isDisabled={values?.wheelBalancingWeight?.rf}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <SwitchField
                                                        disabled={!values?.wheelBalancing?.enable}
                                                        name="wheelBalancingWeight.lr"
                                                        label="LR"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        narrow
                                                        disabled={!values?.wheelBalancing?.enable || !values?.wheelBalancingWeight?.lr}
                                                        label="Qty"
                                                        type="number"
                                                        name="wheelBalancingWeight.lrQty"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <SelectField
                                                        narrow
                                                        name="wheelBalancingWeight.lrReason"
                                                        placeholder="Reason for not balancing"
                                                        options={jobCardReasonForNotBalancingOptions}
                                                        showErrorText={false}
                                                        labelKey="value"
                                                        menuPlacement="top"
                                                        valueKey="id"
                                                        isDisabled={values?.wheelBalancingWeight?.lr}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <SwitchField
                                                        disabled={!values?.wheelBalancing?.enable}
                                                        name="wheelBalancingWeight.rr"
                                                        label="RR"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        narrow
                                                        disabled={!values?.wheelBalancing?.enable || !values?.wheelBalancingWeight?.rr}
                                                        label="Qty"
                                                        name="wheelBalancingWeight.rrQty"
                                                        type="number"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <SelectField
                                                        narrow
                                                        name="wheelBalancingWeight.rrReason"
                                                        placeholder="Reason for not balancing"
                                                        options={jobCardReasonForNotBalancingOptions}
                                                        showErrorText={false}
                                                        labelKey="value"
                                                        valueKey="id"
                                                        menuPlacement="top"
                                                        isDisabled={values?.wheelBalancingWeight?.rr}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <SwitchField
                                                        disabled={!values?.wheelBalancing?.enable}
                                                        name="wheelBalancingWeight.st"
                                                        label="ST"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        narrow
                                                        disabled={!values?.wheelBalancing?.enable || !values?.wheelBalancingWeight?.st}
                                                        label="Qty"
                                                        name="wheelBalancingWeight.stQty"
                                                        type="number"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <SelectField
                                                        narrow
                                                        name="wheelBalancingWeight.stReason"
                                                        placeholder="Reason for not balancing"
                                                        options={jobCardReasonForNotBalancingOptions}
                                                        showErrorText={false}
                                                        labelKey="value"
                                                        menuPlacement="top"
                                                        valueKey="id"
                                                        isDisabled={values?.wheelBalancingWeight?.st}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        narrow
                                                        disabled={!values?.wheelBalancing?.enable}
                                                        label="Total Qty"
                                                        name="wheelBalancingWeight.totalQty"
                                                        type="number"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        narrow
                                                        disabled={!values?.wheelBalancing?.enable}
                                                        label="Rate in grms"
                                                        name="wheelBalancingWeight.rate"
                                                        type="number"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        narrow
                                                        disabled={!values?.wheelBalancing?.enable}
                                                        label="Amt"
                                                        name="wheelBalancingWeight.amount"
                                                        type="number"
                                                        showErrorText={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={12} md={3}>
                            <SelectField
                                options={activeOperatorsOptions}
                                name="operator"
                                placeholder="Select Operator"
                                showErrorText={false}
                                menuPlacement="top"
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disableElevation
                                loading={isSubmitting}
                                loadingPosition="center"
                                fullWidth
                                onClick={() => {
                                    setFieldValue('submit_type', 'close_with_bill');
                                    submitForm();
                                }}
                            >
                                Close With Bill
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disableElevation
                                loading={isSubmitting}
                                loadingPosition="center"
                                fullWidth
                                onClick={() => {
                                    setFieldValue('submit_type', 'close_without_bill');
                                    submitForm();
                                }}
                            >
                                Close Without Bill
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <Button variant="outlined" color="secondary" onClick={handleBack}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
                <DialogTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h3">Tyre Fitment</Typography>
                        {/* <Button onClick={handleClose}>Close</Button> */}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <SelectField
                                name="newTyreFitmentDTO.tyre"
                                label="Tyre*"
                                placeholder="Tyre*"
                                options={activeTyreOptions}
                                labelKey="name"
                                valueKey="id"
                                isClearable
                            />
                            <SelectField
                                name="newTyreFitmentDTO.size"
                                label="Size*"
                                placeholder="Size*"
                                options={activeTyreSizeOptions}
                                labelKey="size"
                                valueKey="id"
                                isClearable
                            />
                            <TextField name="newTyreFitmentDTO.qty" label="Qty*" type="number" />
                            <TextField name="newTyreFitmentDTO.serialNumber" label="Serial Number*" type="number" />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    if (values?.newTyreFitmentDTO.qty === 1) {
                                        setFieldValue('wheelBalancingWeight.lf', false);
                                        setFieldValue('wheelBalancingWeight.lfReason', { id: '10', value: 'New' });
                                    } else if (values?.newTyreFitmentDTO.qty === 2) {
                                        setFieldValue('wheelBalancingWeight.lf', false);
                                        setFieldValue('wheelBalancingWeight.lfReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.rf', false);
                                        setFieldValue('wheelBalancingWeight.rfReason', { id: '10', value: 'New' });
                                    } else if (values?.newTyreFitmentDTO.qty === 3) {
                                        setFieldValue('wheelBalancingWeight.lf', false);
                                        setFieldValue('wheelBalancingWeight.lfReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.rf', false);
                                        setFieldValue('wheelBalancingWeight.rfReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.lr', false);
                                        setFieldValue('wheelBalancingWeight.lrReason', { id: '10', value: 'New' });
                                    } else if (values?.newTyreFitmentDTO.qty === 4) {
                                        setFieldValue('wheelBalancingWeight.lf', false);
                                        setFieldValue('wheelBalancingWeight.lfReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.rf', false);
                                        setFieldValue('wheelBalancingWeight.rfReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.lr', false);
                                        setFieldValue('wheelBalancingWeight.lrReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.rr', false);
                                        setFieldValue('wheelBalancingWeight.rrReason', { id: '10', value: 'New' });
                                    } else if (values?.newTyreFitmentDTO.qty >= 5) {
                                        setFieldValue('wheelBalancingWeight.lf', false);
                                        setFieldValue('wheelBalancingWeight.lfReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.rf', false);
                                        setFieldValue('wheelBalancingWeight.rfReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.lr', false);
                                        setFieldValue('wheelBalancingWeight.lrReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.rr', false);
                                        setFieldValue('wheelBalancingWeight.rrReason', { id: '10', value: 'New' });
                                        setFieldValue('wheelBalancingWeight.st', false);
                                        setFieldValue('wheelBalancingWeight.stReason', { id: '10', value: 'New' });
                                    }
                                    handleClose();
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                sx={{
                                    marginLeft: 1
                                }}
                                variant="outlined"
                                onClick={() => {
                                    setFieldValue('newTyreFitmentDTO', {
                                        tyre: null,
                                        size: null,
                                        qty: 0,
                                        serialNumber: null
                                    });
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} />
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

function ProcessJobCardForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyJobCard}
            onSubmit={handleSubmit}
            validationSchema={jobCardProcessSchema}
            FormContent={ProcessJobCardFormContent}
            validateOnChange={false}
        />
    );
}

export default ProcessJobCardForm;

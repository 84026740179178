import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export
export const saveSMSTemplateRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/sms/save`, payload),
        successCode: 200
    });

export const getSMSTemplateListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/sms/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getSMSTemplateDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/sms/get`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });


export const smsTemplatesTriggerOptionsList = [
    {
        'id': '1',
        'trigerPoint': 'Jobcard Creation'
    },
    {
        'id': '2',
        'trigerPoint': 'Jobcard Close Without Bill'
    },
    {
        'id': '3',
        'trigerPoint': 'Jobcard Close With Bill'
    },
    {
        'id': '4',
        'trigerPoint': 'Wheel Balancing Reminder'
    }
];

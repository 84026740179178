import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';
import moment from 'moment';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import { saveSMSIncentiveSetupRequest } from 'services/sms_incentive_setup';
import IncentiveSetupForm from 'components/insentive-setup/InsentiveSetupForm';
import IncentiveEntryForm from '../../components/incentive-entry/IncentiveEntryForm';
import { saveIncentiveEntryRequest } from '../../services/incentive_entry';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddIncentiveEntryPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.INCENTIVE_ENTRY });

    const navigate = useNavigate();

    const handleAddIncentiveEntry = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { segment, tyre, tyreSize, customer, listPrice, salePrice, quantity, vehicleMake, billNumber, salesDate } = values;
        const data = {
            segment,
            tyre,
            tyreSize,
            customer,
            listPrice,
            salePrice,
            quantity,
            vehicleMake,
            billNumber,
            salesDate
        };
        data.salesDate = moment(data?.salesDate).format('DD-MM-YYYY');
        const payload = payloadCreator(data);
        const response = await saveIncentiveEntryRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.INCENTIVE_ENTRY_LIST);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Incentive Entry" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <IncentiveEntryForm handleSubmit={handleAddIncentiveEntry} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddIncentiveEntryPage;

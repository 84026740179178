import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

function ListProvider({ children, fetcher, initialData = {}, listMutation:extenalListMutation }) {
    const [listItems, setListItems] = useState([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [listMutation, setListMutation] = useState({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [search, setSearch] = useState(initialData.search || {});
    const [sortParams, setSortParams] = useState(
        initialData.sortParams || {
            column: 'name',
            order: 'asc'
        }
    );

    useEffect(() => {
        if (initialData.sortParams) {
            setSortParams(initialData.sortParams);
        }
        if (initialData.search) {
            setSearch(initialData.search);
        }
    }, [initialData]);

    const handleSearch = (newValues = {}) => {
        setPage(1);
        setSearch((prevSearch) => ({
            ...prevSearch,
            ...newValues
        }));
    };

    const listProps = {
        listItems,
        initialLoading,
        loading,
        listMutation,
        page,
        limit,
        totalPages,
        totalResults,
        search,
        setListItems,
        setInitialLoading,
        setLoading,
        setListMutation,
        setPage,
        setLimit,
        setTotalPages,
        setTotalResults,
        setSearch: handleSearch
    };

    const [debouncedSearch] = useDebounce(search, 1000);

    useEffect(() => {
        async function fetchList() {
            const payloadData = {
                search: search ?? '',
                sorted: sortParams,
                limit,
                page
            };
            setLoading(true);

            const data = await fetcher(payloadData);

            setLoading(false);
            setInitialLoading(false);
            if (data.error === false) {
                const resultPage = data.page;
                const resultLimit = data.limit;
                const baseSno = (resultPage - 1) * resultLimit + 1;
                const itemsList = data.results.map((item, index) => ({
                    ...item,
                    sno: baseSno + index
                }));

                setListItems(itemsList);
                setPage(data.page);
                setLimit(data.limit);
                setTotalPages(data.totalPages);
                setTotalResults(data.totalResults);
            }
        }

        fetchList();
    }, [limit, page, debouncedSearch, extenalListMutation]);

    return <>{children(listProps)}</>;
}

export default ListProvider;

import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import SmsTemplateForm from 'components/sms-template/SmsTemplateForm';
import { saveSMSTemplateRequest } from 'services/sms_template';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddSmsTemplatePage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.SMS_TEMPLATE });

    const navigate = useNavigate();

    const handleAddSmsTemplate = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { smsTitle, smsText, status, triggerPoint } = values;
        const data = {
            smsTitle,
            smsText,
            status:{
                id: status,
            },
            triggerPoint
        };
        const payload = payloadCreator(data);
        const response = await saveSMSTemplateRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.SMS_TEMPLATE_LIST);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="SMS Template" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <SmsTemplateForm handleSubmit={handleAddSmsTemplate} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddSmsTemplatePage;

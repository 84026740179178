import React from 'react';
import { Form as FormikForm, Formik } from 'formik';

function FormikFormWrapper({ FormContent, disabledFields=[],...props }) {
    return (
        <Formik {...props}>
            {(formik) => (
                <FormikForm>
                    <FormContent {...formik} disabledFields={disabledFields}/>
                </FormikForm>
            )}
        </Formik>
    );
}

export default FormikFormWrapper;

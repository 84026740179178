import { callApi } from 'utils/callApi';
import { API_BASE_URL } from './index';

export const orderSegmentOptionsList = [
    { id: '1', segment: 'Car Tyre' },
    { id: '2', segment: 'Two Wheeler' },
    { id: '3', segment: 'Three Wheeler' },
    { id: '4', segment: 'LCV' },
    { id: '5', segment: 'SCV' },
    { id: '6', segment: 'HCV' },
    { id: '7', segment: 'OTR' }
];

export const orderStatusOptionsList = [
    {
        id: '1',
        status: 'Pending'
    },
    {
        id: '3',
        status: 'Call Made'
    },
    {
        id: '5',
        status: 'Sale Made'
    },
    {
        id: '6',
        status: 'Bought from other'
    },
    {
        id: '7',
        status: 'Close without sale'
    },
    {
        id: '4',
        status: 'Deleted'
    }
];

// eslint-disable-next-line import/prefer-default-export
export const getOrdersListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/order/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getOrderDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/order/get`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getOrderCommentHistoryRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/order/comment/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const saveOrderRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/order/save`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });

export const getOrderExcelRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/order/excel/export`, payload, { responseType: 'blob' }),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true,
        checkResponseData: false
    });

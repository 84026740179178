import React, { useMemo } from 'react';
import { TextField as MaterialTextField } from '@mui/material';
import useFieldFast from './useFieldFast';
import isEqual from 'react-fast-compare';

const TextFieldInput = React.memo(
    (props) => (
        <>
            <MaterialTextField {...props} />
        </>
    ),
    isEqual
);

function TextField({ label, controlled = false, showErrorText = true, narrow = false, ...props }) {
    const [{ value, ...field }, meta] = useFieldFast(props);

    const inputProps = useMemo(() => {
        if (narrow) {
            return {
                style: {
                    padding: '5px 14px'
                }
            };
        }
        return {};
    }, [narrow]);

    const inputLabelProps = useMemo(() => {
        if (narrow) {
            return {
                style: { top: '-4px' }
            };
        }
        return {};
    }, [narrow]);

    return (
        <TextFieldInput
            fullWidth
            variant="outlined"
            size="small"
            error={meta.touched && !!meta.error}
            {...(showErrorText ? { helperText: meta.touched && !!meta.error ? meta.error : ' ' } : {})}
            label={label}
            value={value}
            inputProps={inputProps}
            InputLabelProps={inputLabelProps}
            {...field}
            {...props}
        />
    );
}

export default TextField;

import md5 from 'md5';
import sha256 from 'sha256';
import moment from 'moment';

export const encryptPassword = (password) => sha256(md5(password));

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return null;
};

export const extractValue = (obj = {}, valueKey = 'value') => obj[valueKey];

export const extractObjectByValue = (array, value, valueKey = 'value') => array.find((item) => item[valueKey] === value);

export const pickProperties = (object, keys) =>
    keys.reduce((obj, key) => {
        if (object && Object.prototype.hasOwnProperty.call(object, key)) {
            // eslint-disable-next-line no-param-reassign
            obj[key] = object[key];
        }
        return obj;
    }, {});

export function base64ToArrayBuffer(base64) {
    console.log('-> base64', base64);
    // const binaryString = window.atob(base64); // Comment this if not using base64
    const binaryString = base64;
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

export function createAndDownloadBlobFile(body, filename, extension = 'pdf', download = false) {
    let type = extension;
    const fileName = `${filename}.${extension}`;
    if (extension === 'xlsx') {
        type = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
    }
    const blob = new Blob([body], { type: `application/${type}` });

    console.log('-> blob', blob);
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            if (download) {
                link.setAttribute('download', fileName);
            } else {
                link.setAttribute('target', '_blank');
            }
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export const downloadFileFromResponse = (response, fileName = 'jobcard', extension = 'pdf', download = false) => {
    // const arrayBuffer = base64ToArrayBuffer(response.FileData);
    // console.log('-> arrayBuffer', arrayBuffer);
    createAndDownloadBlobFile(response.data, fileName, extension, download);

    // const url = window.URL.createObjectURL(new Blob([response]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', fileName); //or any other extension
    // document.body.appendChild(link);
    // link.click();
};

export const createBlobFromResponse = (response, extension = 'jpeg') => new Blob([response], { type: `application/${extension}` });

export const createUrlFromBlob = (blob) => URL.createObjectURL(blob);

export const getFileLocalUrlFromResponse = (response, extension = 'jpeg') => {
    const blob = createBlobFromResponse(response, extension);
    return createUrlFromBlob(blob);
};

export const downloadFileFromLocalUrl = (url, fileName) => {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const getFirstDayOfCurrentMonth = () => {
    const startOfMonth = moment().startOf('month');
    return startOfMonth.toDate();
};

export const getLastDayOfCurrentMonth = () => {
    const endOfMonth = moment().endOf('month');
    return endOfMonth.toDate();
};

import { callApi } from 'utils/callApi';
import { API_BASE_URL } from './index';

// eslint-disable-next-line import/prefer-default-export
export const getDashboardCardDataRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/dashboard/carddata`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getDashboardIncentiveStatusRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/dashboard/incentivestatus`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const fetchIncentiveShortTimeSalesDataRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/dashboard/incentiveactiveshorttimeincentive`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getMyReminders = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/dashboard/myreminders`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: false
    });

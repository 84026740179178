import React, { useState } from 'react';
import Breadcrumb from 'components/ui/Breadcrumb';
import { Box, Card, CardContent, Grid, LinearProgress } from '@mui/material';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ListProvider from 'components/ui/ListProvider';
import { getComplaintCommentHistoryRequest } from '../../services/complaints';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function ComplaintCommentsListPage(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({
        search: { name: '', status: { id: 1 } },
        sortParams: {
            column: 'name',
            order: 'asc'
        }
    });

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.TYRE });

    const { complaintId } = useParams();

    const columns = [
        {
            field: 'sno',
            headerName: 'Sno',
            flex: 4,
            minWidth: 150
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'remark',
            headerName: 'Comment',
            flex: 4,
            minWidth: 150
        },
        {
            field: 'remarkDate',
            headerName: 'Date',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'remarkBy',
            headerName: 'Operator',
            flex: 4,
            minWidth: 100
        }
    ];

    const fetchComplaintsList = async () => {
        const payload = createPayload({ id: complaintId });
        const response = await getComplaintCommentHistoryRequest(payload);
        return {
            error: response.error,
            results: response,
            page: 1,
            limit: 15,
            totalPages: 1,
            totalResults: response.length
        };
    };

    return (
        <>
            <Breadcrumb title="Complaint Comments" />
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ListProvider fetcher={fetchComplaintsList} initialData={initialData}>
                                {(listProps) => (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box
                                                p={1}
                                                style={{
                                                    // height: 500,
                                                    width: '100%'
                                                }}
                                            >
                                                <Box className={classes.progressBarContainer}>
                                                    {listProps.loading && <LinearProgress />}
                                                </Box>
                                                {listProps.listItems && !listProps.loading && (
                                                    <>
                                                        <DataGrid
                                                            rows={listProps.listItems}
                                                            columns={columns}
                                                            pagination={false}
                                                            paginationMode="server"
                                                            pageSize={listProps.limit}
                                                            page={listProps.page}
                                                            row
                                                            autoHeight
                                                            disableColumnMenu
                                                        />
                                                    </>
                                                )}
                                                {listProps.initialLoading && (
                                                    <Grid container spacing={1}>
                                                        {[...Array(12)].map((_, i) => (
                                                            <Grid key={i} item xs={12}>
                                                                <Skeleton variant="rect" height={40} />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </ListProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default ComplaintCommentsListPage;

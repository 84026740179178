import React, { useCallback, useEffect } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import SelectField from '../../libs/formik_fields/SelectField';
import { getQuotationDefaultSummaryRequest, quotationStatusOptionsList } from '../../services/quotation';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import DateInputField from '../../libs/formik_fields/DateInputField';
import appModules from '../../appModules';
import { FieldArray } from 'formik';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { orderSegmentOptionsList } from '../../services/order';
import useFetchSelectOptions from '../../hooks/useFetchSelectOptions';
import { getActiveUsersListForJobCardProcess } from '../../services/job_card';

const emptyOrder = {
    segment: null,
    name: '',
    contactNo: '',
    address: '',
    orderDescription: '',
    followupDate: new Date(),
    assignTo: null,
    status: {
        id: '1',
        status: 'Pending'
    },
    remark: ''
};

const orderSchema = yup.object().shape({
    segment: yup.mixed().required('Please select segment.'),
    name: yup.string().required('Please enter name.'),
    contactNo: yup.string().required('Please enter contact Number.'),
    address: yup.string().required('Please enter address.'),
    orderDescription: yup.string().required('Please enter description.'),
    followupDate: yup.mixed().required('Please enter followup date.'),
    assignTo: yup.mixed().required('Please select assigned to.'),
    status: yup.mixed().required('Please select status.'),
    remark: yup.string('Please enter valid remark.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const OrderFormContent = (formik) => {
    const { isSubmitting, values, errors, setFieldValue } = formik;
    const { editMode } = values;
    console.log('-> errors', errors);

    const navigate = useNavigate();
    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.ORDER });

    const { options: activeOperatorsOptions, loading: activeOperatorsOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = payloadCreator({});
            return getActiveUsersListForJobCardProcess(payload);
        }
    });

    useEffect(() => {
        async function fetchData() {
            if (!editMode) {
                const payload = payloadCreator({});
                const result = await getQuotationDefaultSummaryRequest(payload);
                console.log('-> result', result);
                if (result.error === false) {
                    setFieldValue('summary', result.summary);
                }
            }
        }

        fetchData();
    }, [!!editMode]);

    const handleBack = useCallback(() => {
        navigate(appUrls.ORDER);
    }, [navigate]);

    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12} md={6} px={1}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <SelectField
                            name="segment"
                            placeholder="Segment"
                            valueKey="id"
                            labelKey="segment"
                            options={orderSegmentOptionsList}
                            isDisabled={editMode}
                        />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="name" label="Name*" disabled={editMode} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="contactNo" label="Phone No*" disabled={editMode} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="address" label="Address*" disabled={editMode} multiline rows={3} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="orderDescription" label="Order Description*" multiline rows={3} disabled={editMode} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <SelectField
                            name="assignTo"
                            placeholder="Assigned To*"
                            valueKey="id"
                            labelKey="name"
                            options={activeOperatorsOptions}
                            isDisabled={editMode}
                        />
                    </Grid>

                    <Grid itemx xs={12}>
                        <SelectField
                            name="status"
                            placeholder="Status"
                            valueKey="id"
                            labelKey="status"
                            options={quotationStatusOptionsList}
                        />
                    </Grid>

                    <Grid itemx xs={12}>
                        <TextField name="remark" label="Status Remark" />
                    </Grid>
                    <Grid itemx xs={12}>
                        <DateInputField name="followupDate" label="Followup Date*" fullWidth />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} pt={2}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            loading={isSubmitting}
                            loadingPosition="center"
                        >
                            Save
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                                ml: 1
                            }}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

function QuotationForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyOrder}
            onSubmit={handleSubmit}
            validationSchema={orderSchema}
            FormContent={OrderFormContent}
        />
    );
}

export default QuotationForm;

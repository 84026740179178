import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appUrls from 'appUrls';
import Breadcrumb from 'components/ui/Breadcrumb';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import OperatorForm from 'components/operator/OperatorForm';
import { getUserDetailsRequest, saveUserRequest } from 'services/user';
import JobCardForm from '../../components/job-card/JobCardForm';
import { downloadJobCardPdfRequest, getJobCardDetailsByIdRequest, saveJobCardRequest } from '../../services/job_card';
import moment from 'moment';
import { downloadFileFromResponse } from '../../utils';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}));

// eslint-disable-next-line no-unused-vars
function EditJobCardPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.JOB_CARD });

    const navigate = useNavigate();

    const { jobCardId } = useParams();
    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            setDetailsLoading(true);
            const detailsReqPayload = payloadCreator({ id: jobCardId });
            const data = await getJobCardDetailsByIdRequest(detailsReqPayload);
            if (data.error === false) {
                const detailsObj = data;
                if (detailsObj.customer) {
                    detailsObj.customer.vehicleRegNo = {
                        value: detailsObj.customer.vehicleRegNo,
                        label: detailsObj.customer.vehicleRegNo,
                        isNew: true
                    };
                    // detailsObj.customer.mobile = {
                    //     value: detailsObj.customer.mobile,
                    //     label: detailsObj.customer.mobile,
                    //     mobileNo: detailsObj.customer.mobile,
                    //     regNo: '',
                    //     isNew: true
                    // };
                }

                detailsObj.editMode = true;
                console.log('-> detailsObj', detailsObj);
                setDetails(detailsObj);
            }
            setDetailsLoading(false);
        };
        fetchDetails();
    }, [jobCardId]);

    const handleOperatorUpdate = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const {
            customer,
            jobCardCreateDateTime,
            tyreTubeFitment,
            wheelAlignment,
            n2,
            tyreRepair,
            wheelBalancing,
            printPdf,
            jobCardNo,
            remark
        } = values;
        const data = {
            id: jobCardId,
            // customer: { ...customer, vehicleRegNo: customer?.vehicleRegNo?.value ?? '',  mobile: customer?.mobile?.mobileNo ?? '' },
            customer: { ...customer, vehicleRegNo: customer?.vehicleRegNo?.value ?? '' },
            jobCardCreateDateTime,
            tyreTubeFitment,
            wheelAlignment,
            n2,
            tyreRepair,
            wheelBalancing,
            remark
        };

        const reqPayload = payloadCreator(data);
        const response = await saveJobCardRequest(reqPayload);
        if (response.error === false) {
            resetForm();
            if (printPdf) {
                const data = { id: jobCardId };
                const payload = payloadCreator(data);
                const result = await downloadJobCardPdfRequest(payload);
                if (!result.error) {
                    downloadFileFromResponse(result, jobCardNo, 'pdf');
                }
            }

            navigate(appUrls.JOB_CARD);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Job Card" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {!detailsLoading && details ? (
                                            <JobCardForm initialValues={details} handleSubmit={handleOperatorUpdate} />
                                        ) : null}

                                        {detailsLoading && (
                                            <Grid container spacing={3}>
                                                {[...Array(8)].map((_, index) => (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <Skeleton variant="rect" height={40} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EditJobCardPage;

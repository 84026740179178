import axios from 'axios';
import { extractDataFromResponse, parseApiErrorResponse } from '../utils/apiUtils';
import { callApiThunk } from 'utils/callApiThunk';
import { API_BASE_URL } from './index';

const AUTH_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/admin/auth`;
const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_API_BASE_URL}/admin/users/change-password`;
const LOGOUT_URL = `${AUTH_BASE_URL}/logout`;
//
// export const loginRequest = async ({ email, password }) =>
//     callApi({
//         requestFunction: () => axios.post(`${AUTH_BASE_URL}/login`, { email, password }),
//         successCode: 200,
//         callRefreshTokenOnAuthError: false
//     });

// export const registerRequest = async ({ email, password }) =>
//     callApi({
//         requestFunction: () => axios.post(`${AUTH_BASE_URL}/register`, { email, password }),
//         successCode: 201,
//         callRefreshTokenOnAuthError: false
//     });

// eslint-disable-next-line import/prefer-default-export
export const loginRequest = async ({ email, password }) => {
    try {
        const response = await axios.post(`${AUTH_BASE_URL}/login`, { email, password });
        return extractDataFromResponse({
            response
        });
    } catch (error) {
        return parseApiErrorResponse({
            error
        });
    }
};

export const changePasswordRequest = async ({ password, currentPassword, confirmPassword }) => {
    try {
        const response = await axios.post(`${CHANGE_PASSWORD_URL}`, { password, currentPassword, confirmPassword });
        return extractDataFromResponse({
            response
        });
    } catch (error) {
        return parseApiErrorResponse({
            error
        });
    }
};

export const logoutRequest = async ({ accessToken, refreshToken }) => {
    try {
        const response = await axios.post(
            `${LOGOUT_URL}`,
            { refreshToken },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        return extractDataFromResponse({
            response
        });
    } catch (error) {
        return parseApiErrorResponse({
            error
        });
    }
};

export const getBuildInfoRequest = async ({ thunkApi }) =>
    callApiThunk({
        requestFunction: (axios) => axios.get(`${API_BASE_URL}/buildinfo`),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: false,
        thunkApi,
        useAuth: false
    });


export const getUserInfoRequest = async ({ thunkApi }) =>
    callApiThunk({
        requestFunction: (axios) => axios.get(`${API_BASE_URL}/loaduser`),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true,
        thunkApi,
        useAuth: true
    });
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callApiThunk } from 'utils/callApiThunk';

const initialState = {
    initialLoading: true,
    loading: true,
    servicesList: [],
    formattedServiceList: []
};

const SERVICE_LIST_URL = `${process.env.REACT_APP_API_BASE_URL}/service/list`;

export const fetchServiceList = createAsyncThunk('service/list', async (data, thunkApi) => {
    const { rejectWithValue } = thunkApi;

    const result = await callApiThunk({
        requestFunction: (axios) => axios.post(`${SERVICE_LIST_URL}`, data),
        successCode: 200,
        callRefreshTokenOnAuthError: false,
        thunkApi,
        useAuth: true,
        showToastOnError: true,
        showToastOnSuccess: false
    });

    if (result.error === false) {
        return result;
    }
    return rejectWithValue(result);
});

const serviceSlice = createSlice({
    name: 'service',
    initialState,
    extraReducers: {
        [fetchServiceList.pending]: (state) => {
            state.loading = true;
        },
        [fetchServiceList.fulfilled]: (state, action) => {
            const services = action.payload.map((item) => ({
                ...item,
                status: item?.status?.id === 1
            }));
            state.loading = false;
            state.initialLoading = false;
            state.servicesList = action.payload;
            state.formattedServiceList = services;
        },
        [fetchServiceList.rejected]: (state) => {
            state.loading = false;
            state.initialLoading = false;
            state.servicesList = [];
            state.formattedServiceList = [];
        }
    }
});

// selectors
export const selectServiceLoading = (state) => state.service.loading;
export const selectInitialServiceLoading = (state) => state.service.initialLoading;
export const selectServiceList = (state) => state.service.servicesList;
export const selectFormattedServiceList = (state) => state.service.formattedServiceList;

export const selectIsServiceActive = (serviceName) => (state) => {
    const formattedServices = selectFormattedServiceList(state);
    const serviceObj = formattedServices.find((service) => service.name === serviceName);
    return serviceObj?.status ?? false;
};
export default serviceSlice.reducer;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/slices/authSlice';

function LogoutPage(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
    }, []);

    return <div />;
}

export default LogoutPage;

import React from 'react';
import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

function EditButton(props) {
    return (
        <IconButton aria-label="delete" {...props}>
            <EditIcon />
        </IconButton>
    );
}

export default EditButton;

import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export
export const saveServiceSetupItemRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/service/save`, payload),
        successCode: 200
    });

export const getServiceSetupListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/service/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getServiceRateModelListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/service/rate/model`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const jobCardFormServiceKeysMap = {
    tyre_tube_fitment: 'tyreTubeFitment',
    wheel_alignment: 'wheelAlignment',
    n_2: 'n2',
    tyre_repair: 'tyreRepair',
    wheel_balancing: 'wheelBalancing',
    wheel_balancing_weight: 'wheelBalancingWeight',
    tyre_stock_mgmt: 'tyreStockMgmt',
    other_product_sales: 'otherProductSales',
    new_tyre_fitment: 'newTyreFitment'
};

import React, { useCallback, useEffect } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import SelectField from '../../libs/formik_fields/SelectField';
import StatusSwitchField from '../../libs/formik_fields/StatusSwitchField';
import { smsTemplatesTriggerOptionsList } from '../../services/sms_template';
import useFetchSelectOptions from '../../hooks/useFetchSelectOptions';
import { getActiveUsersListForJobCardProcess } from '../../services/job_card';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import { getActiveTyreListRequest } from '../../services/tyre';
import { getActiveTyreSizeListRequest } from '../../services/tyre_size';
import { smsIncentiveSegmentOptionsList } from '../../services/sms_incentive_setup';
import DateTimeInputField from 'libs/formik_fields/DateInputField';

const emptySmsIncentiveSetup = {
    segment: null,
    tyre: null,
    tyreSize: null,
    maxDiscount: 0,
    incentive: 0,
    status: 1,
    timeltOffer: 2,
    sDate: null,
    eDate: null
};

const smsIncentiveSchema = yup.object().shape({
    segment: yup.mixed().required('Please select segment.'),
    tyre: yup.mixed().required('Please select tyre.'),
    tyreSize: yup.mixed().required('Please select tyre size.'),
    maxDiscount: yup.mixed().required('Please enter max discount.'),
    incentive: yup.mixed().required('Please enter incentive per tyre.'),
    sDate: yup.mixed().when('timeltOffer', { is: 1, then: yup.string().required('Please enter start date.') }),
    eDate: yup.mixed().when('timeltOffer', { is: 1, then: yup.string().required('Please enter end date.') })
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const IncentiveFormContent = (formik) => {
    const { isSubmitting, values, errors, setFieldValue } = formik;
    const { editMode } = values;

    const navigate = useNavigate();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.INCENTIVE_SETUP });

    const { options: activeTyres, loading: activeTyresLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = payloadCreator({});
            return getActiveTyreListRequest(payload);
        }
    });

    const { options: activeTyreSize, loading: activeTyreSizeLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = payloadCreator({});
            return getActiveTyreSizeListRequest(payload);
        }
    });

    const handleBack = useCallback(() => {
        navigate(appUrls.INCENTIVE_SETUP_LIST);
    }, [navigate]);

    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} px={1}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <SelectField
                            name="segment"
                            placeholder="Segment"
                            valueKey="id"
                            labelKey="segment"
                            options={smsIncentiveSegmentOptionsList}
                        />
                    </Grid>
                    <Grid itemx xs={12}>
                        <SelectField name="tyre" placeholder="Tyre" valueKey="id" labelKey="name" options={activeTyres} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <SelectField name="tyreSize" placeholder="Tyre Size" valueKey="id" labelKey="size" options={activeTyreSize} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <StatusSwitchField name="status" label="Status" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField type="number" name="maxDiscount" label="0 Incentive when Discount is*" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type="number" name="incentive" label="Incentive per tyre*" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                <Grid container>
                    <Grid itemx xs={4}>
                        <StatusSwitchField name="timeltOffer" label="Short Term Offer" />
                    </Grid>
                    <Grid item xs={4} paddingRight={5}>
                        <DateTimeInputField name="sDate" label="Start Date" />
                    </Grid>
                    <Grid item xs={4} paddingRight={5}>
                        <DateTimeInputField name="eDate" label="End Date" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} pt={2}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            loading={isSubmitting}
                            loadingPosition="center"
                        >
                            Save
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                                ml: 1
                            }}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

function IncentiveSetupForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptySmsIncentiveSetup}
            onSubmit={handleSubmit}
            validationSchema={smsIncentiveSchema}
            FormContent={IncentiveFormContent}
        />
    );
}

export default IncentiveSetupForm;

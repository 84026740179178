import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export
export const saveTyreBrandRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyrebrand/save`, payload),
        successCode: 200
    });

export const getTyreBrandListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyrebrand/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getTyreBrandDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyrebrand/get`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getActiveTyreBrandListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyrebrand/active/list`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

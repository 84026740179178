import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import { FieldArray } from 'formik';
import RateListModal from './RateListModal';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import { getServiceRateModelListRequest } from '../../services/service_setup';
import StatusSwitchField from '../../libs/formik_fields/StatusSwitchField';

const emptyVehicle = {
    name: '',
    description: '',
    status: 1,
    model: []
};

const vehicleSchema = yup.object().shape({
    name: yup.string().required('Please enter name of vehicle.'),
    description: yup.string('Please enter valid description.'),
    status: yup.mixed().required('Status is required.'),
    model: yup
        .array()
        .of(
            yup.object().shape({
                name: yup.string().min(2, 'Model name is required.').required('Model name is required.'),
                // description: yup.string().required('Model description is required.'),
                status: yup.mixed().required('Model status is required.')
            })
        )
        .min(1, 'Please add at least one model item.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const VehicleFormContent = ({ isSubmitting, setFieldValue, values }) => {
    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(appUrls.VEHICLE);
    }, [navigate]);

    const classes = useStyles();

    const [rateListItems, setRateListItems] = useState([]);

    const [vehiclePayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.VEHICLE });

    useEffect(() => {
        async function fetchData() {
            const payload = vehiclePayloadCreator({});
            const result = await getServiceRateModelListRequest(payload);
            if (result.error === false) {
                setRateListItems(result);
            }
        }
        fetchData();
    }, []);

    const handleRatesBulkUpdateSubmit = (rateFormValues) => {
        if (values.model && Array.isArray(values.model) && values.model.length > 0) {
            values.model.forEach((item, index) => {
                setFieldValue(`model.${index}.rate`, rateFormValues.rates);
            });
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField name="name" label="Vehicle*" />
                {values.editMode && <StatusSwitchField name="status" label="Status" />}
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField name="description" label="Description" rows={4.5} multiline />
            </Grid>
            <Grid item xs={12}>
                <FieldArray
                    name="model"
                    render={(arrayHelpers) => (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography m={0} variant="h3">
                                    Available Models
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    {/* <Grid */}
                                    {/*    item */}
                                    {/*    xs={8} */}
                                    {/*    sx={{ */}
                                    {/*        display: 'flex', */}
                                    {/*        alignItems: 'center', */}
                                    {/*        justifyContent: 'flex-end' */}
                                    {/*    }} */}
                                    {/* > */}
                                    {/* <SelectInput */}
                                    {/*    id="tyreSize" */}
                                    {/*    placeholder="Size" */}
                                    {/*    valueKey="id" */}
                                    {/*    labelKey="size" */}
                                    {/*    options={tyreSizeOptionsList} */}
                                    {/*    onChange={setSelectedSize} */}
                                    {/*    value={selectedSize} */}
                                    {/*    menuPlacement="top" */}
                                    {/* /> */}

                                    {/* </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <RateListModal
                                            rateList={rateListItems}
                                            buttonText="Rate List Bulk Edit"
                                            onSubmit={handleRatesBulkUpdateSubmit}
                                        />
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            sx={{
                                                ml: 1
                                            }}
                                            onClick={() => {
                                                arrayHelpers.push({
                                                    name: ' ',
                                                    // description: ' ',
                                                    status: 1,
                                                    rate: rateListItems,
                                                    isNew: true
                                                });
                                            }}
                                        >
                                            Add Model
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} pb={2}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sno</TableCell>
                                                <TableCell align="center">Name</TableCell>
                                                {/* <TableCell align="center">Description</TableCell> */}
                                                <TableCell align="center">Status</TableCell>
                                                <TableCell align="left">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                        >
                                            {values.model.map((row, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell valign="top" align="center">
                                                        <TextField name={`model["${index}"].name`} showErrorText={false} />
                                                    </TableCell>
                                                    {/* <TableCell valign="top" align="center"> */}
                                                    {/*    <TextField name={`model["${index}"].description`} showErrorText={false} /> */}
                                                    {/* </TableCell> */}
                                                    <TableCell valign="top" align="center">
                                                        <StatusSwitchField name={`model["${index}"].status`} center />
                                                    </TableCell>
                                                    <TableCell valign="top" align="left">
                                                        <RateListModal
                                                            rateList={row.rate}
                                                            buttonText={row.isNew ? 'Define Rate' : 'Update Rate'}
                                                            onSubmit={(values) => {
                                                                setFieldValue(`model["${index}"].rate`, values.rates);
                                                            }}
                                                        />
                                                        {row.isNew && (
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {values.model.length === 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={4} align="center">
                                                        Please add at least one model
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )}
                />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                >
                    Save
                </LoadingButton>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        ml: 1
                    }}
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Grid>
            {/* <Grid item xs={12}> */}
            {/*    <RateListForm/> */}
            {/* </Grid> */}
        </Grid>
    );
};

function VehicleForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyVehicle}
            onSubmit={handleSubmit}
            validationSchema={vehicleSchema}
            FormContent={VehicleFormContent}
        />
    );
}

export default VehicleForm;

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { useEffect, useState } from 'react';
import * as Icons from '@mui/icons-material';
import { IconDashboard } from '@tabler/icons';
import { useSelector } from 'react-redux';
import { selectUserMenus } from '../../../../store/slices/authSlice';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [menuItems, setMenuItems] = useState();

    const userMenuItems = useSelector(selectUserMenus);



    useEffect(() => {
        const menus = userMenuItems.map((menuItem) => {
            const mainItem = {
                id: menuItem.id,
                title: menuItem.displayname,
                type: menuItem.submenus ? 'collapse' : 'item',
                url: menuItem.path,
                icon: menuItem.icon ? Icons[menuItem.icon] : IconDashboard,
                breadcrumbs: false
            };
            if (menuItem.submenus) {
                mainItem.children = menuItem.submenus.map((submenuItem) => ({
                    id: submenuItem.id,
                    title: submenuItem.displayname,
                    type: 'item',
                    url: submenuItem.path,
                    icon: submenuItem.icon ? Icons[submenuItem.icon] : IconDashboard,
                    breadcrumbs: false
                }));
            }
            return mainItem;
        });
        const obj = {
            items: [
                {
                    id: 'main-routes',
                    type: 'group',
                    children: menus ?? []
                }
            ]
        };
        setMenuItems(obj);
    }, [userMenuItems]);

    if (!menuItems) {
        return '';
    }
    console.log('-> menuItems', menuItems);

    const navItems = menuItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;

import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import OrderForm from '../../components/order/OrderForm';
import { saveOrderRequest } from '../../services/order';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddOrderPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.ORDER });

    const navigate = useNavigate();

    const handleAddOrder = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { segment, name, contactNo, orderDescription, address, assignTo, followupDate, remark, status } = values;
        const data = {
            segment,
            name,
            contactNo,
            address,
            remark,
            status,
            orderDescription,
            followupDate: moment(followupDate).format('DD-MM-YYYY'),
            assignTo
        };
        const payload = payloadCreator(data);
        const response = await saveOrderRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.ORDER);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Add Order" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <OrderForm handleSubmit={handleAddOrder} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddOrderPage;

import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { TableCell, TableRow } from '@mui/material';
import StatusField from '../../libs/formik_fields/StatusField';
import SelectField from '../../libs/formik_fields/SelectField';
import SwitchField from '../../libs/formik_fields/SwitchField';

function OperatorMenuItemCheckBox({ index, item, allMenuOptions }) {
    const [subMenuLoading, setSubMenuLoading] = useState(false);
    const { values } = useFormikContext();
    const selectedMenu = useMemo(() => values.menus[index], [values.menus]);
    const subMenuOptions = useMemo(() => selectedMenu?.submenus ?? [], [selectedMenu]);

    // useEffect(() => {
    //     setSubMenuLoading(true);
    //     setTimeout(() => {
    //         setSubMenuLoading(false);
    //     }, 0);
    // }, [subMenuOptions]);

    return (
        <>
            {subMenuOptions && Array.isArray(subMenuOptions) && subMenuOptions.length > 0 ? (
                <>
                    {subMenuOptions.map((subMenu, subMenuIndex) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {subMenuIndex === 0 && (
                                <>
                                    <TableCell
                                        style={{
                                            verticalAlign: 'top',
                                            paddingTop: '0.75rem',
                                        }}
                                        component="th"
                                        scope="row"
                                        rowSpan={subMenuOptions.length}
                                    >
                                        {index + 1}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            verticalAlign: 'top'
                                        }}
                                        valign="top"
                                        align="center"
                                        rowSpan={subMenuOptions.length}
                                    >
                                        <SwitchField name={`menus["${index}"].hasAccess`} label={selectedMenu.displayname} showErrorText={false} />
                                    </TableCell>
                                </>
                            )}

                            <TableCell valign="top" align="center">
                                <SwitchField
                                    name={`menus["${index}"].submenus["${subMenuIndex}"].hasAccess`}
                                    label={subMenu.displayname}
                                    disabled={!selectedMenu.hasAccess}
                                    showErrorText={false}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            ) : (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                        {index + 1}
                    </TableCell>
                    <TableCell valign="top" align="center">
                        <SwitchField name={`menus["${index}"].hasAccess`} label={selectedMenu.displayname} />
                    </TableCell>
                    <TableCell valign="top" align="center" />
                </TableRow>
            )}
            {/* <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}> */}
            {/*    <TableCell component="th" scope="row"> */}
            {/*        {index + 1} */}
            {/*    </TableCell> */}
            {/*    <TableCell valign="top" align="center"> */}
            {/*        <SwitchField name={`menus["${index}"].hasAccess`} label={selectedMenu.displayname} /> */}
            {/*    </TableCell> */}
            {/*    <TableCell valign="top" align="center"> */}
            {/*        {subMenuLoading || ( */}
            {/*            <> */}
            {/*                {subMenuOptions.map((subMenu, subMenuIndex) => ( */}
            {/*                    <SwitchField */}
            {/*                        name={`menus["${index}"].menu.submenus["${subMenuIndex}"].hasAccess`} */}
            {/*                        label={subMenu.displayname} */}
            {/*                        disabled={!selectedMenu.hasAccess} */}
            {/*                    /> */}
            {/*                ))} */}
            {/*            </> */}
            {/*        )} */}
            {/*    </TableCell> */}
            {/* </TableRow> */}
        </>
    );
}

export default OperatorMenuItemCheckBox;

import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';

const emptyChangePassword = {
    currentPassword: '',
    password: '',
    confirmPassword: ''
};

const changePasswordSchema = yup.object().shape({
    currentPassword: yup.string().required('Please enter name of institute.'),
    password: yup.string().required('Please enter address of institute.'),
    confirmPassword: yup.string().required('Please enter address of institute.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const InstituteFormContent = (formik) => {
    const { isSubmitting } = formik;

    const navigate = useNavigate();

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField type="password" name="currentPassword" label="Current Password*" />
                <TextField type="password" name="password" label="Password*" />
                <TextField type="password" name="confirmPassword" label="Confirm Password*" />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                >
                    Save
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

function InstituteForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyChangePassword}
            onSubmit={handleSubmit}
            validationSchema={changePasswordSchema}
            FormContent={InstituteFormContent}
        />
    );
}

export default InstituteForm;

import { Link } from 'react-router-dom';

// material-ui
import { Box, ButtonBase, Chip, Typography } from '@mui/material';

// project imports
import logoImg from 'assets/images/logo-dark.svg';
import appUrls from '../../../appUrls';
import { getSiteAssets } from '../../../assetProvider';
import { useSelector } from 'react-redux';
import { selectBuildSite } from '../../../store/slices/authSlice';
import { useMemo } from 'react';

// ==============================|| MAIN LOGO ||============================== //

const MyReminder = ({ data, openurl }) => {
    const siteName = useSelector(selectBuildSite);
    const siteAssets = useMemo(() => getSiteAssets(siteName), [siteName]);

    return (
        <Box
            disableRipple
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Chip label={data} color="primary" component={Link} to={openurl} />
        </Box>
    );
};

export default MyReminder;

import { createSlice } from '@reduxjs/toolkit';

import { login, logout } from 'store/slices/authSlice';

const initialState = {
    accessToken: '',
    refreshToken: ''
};

const authSlice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        setTokens: (state, action) => {
            const { accessToken, refreshToken } = action.payload;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.accessToken = action.payload.token;
        },
        [logout]: (state) => {
            state.accessToken = '';
            state.refreshToken = '';
        },
    }
});

export const { setTokens } = authSlice.actions;

// selectors
export const selectAuthLoading = (state) => state.auth.authLoading;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

export const selectUserId = (state) => state.auth.data?.user_id;
export const selectUserName = (state) => state.auth.data?.name;
export const selectUserEmail = (state) => state.auth.data?.email;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectIsRehydrated = (state) => state.auth._persist.rehydrated;

export default authSlice.reducer;

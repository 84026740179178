import { callApi } from '../utils/callApi';
import { API_BASE_URL } from './index';

export const quotationSegmentOptionsList = [
    { id: '1', segment: 'Car Tyre' },
    { id: '2', segment: 'Two Wheeler' },
    { id: '3', segment: 'Three Wheeler' },
    { id: '4', segment: 'LCV' },
    { id: '5', segment: 'SCV' },
    { id: '6', segment: 'HCV' },
    { id: '7', segment: 'OTR' }
];

export const quotationCustomerTitleList = [
    { id: 'Mr.', value: 'Mr.' },
    { id: 'Mrs.', value: 'Mrs.' },
    { id: 'M/S.', value: 'M/S.' },
    { id: 'Ms.', value: 'Ms.' }
];

export const quotationStatusOptionsList = [
    {
        id: '1',
        status: 'Pending'
    },
    {
        id: '3',
        status: 'Call Made'
    },
    {
        id: '5',
        status: 'Sale Made'
    },
    {
        id: '6',
        status: 'Bought from other'
    },
    {
        id: '7',
        status: 'Close without sale'
    },
    {
        id: '4',
        status: 'Deleted'
    }
];

// eslint-disable-next-line import/prefer-default-export
export const getQuotationsListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/quotation/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getQuotationDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/quotation/get`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getQuotationCommentHistoryRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/quotation/comment/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const saveQuotationRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/quotation/save`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });

export const getQuotationDefaultSummaryRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/quotation/defaultsummary`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const downloadQuotationPdfRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/quotation/print`, payload, { responseType: 'blob' }),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true,
        checkResponseData: false
    });

export const getQuotationExcelRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/quotation/excel/export`, payload, { responseType: 'blob' }),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true,
        checkResponseData: false
    });
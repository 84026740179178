import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import lodash from 'lodash';
import { useField } from 'formik';
import { makeStyles } from '@mui/styles';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CalendarToday } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop:2,
        marginBottom: 4
        // marginBottom: theme.spacing(1),
    },
    error: {
        color: theme.palette.error.main
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const SelectInput = React.memo((props) => <Select {...props} />, lodash.isEqual);

export const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 1000
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: 12,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 8,
        paddingRight: 8,
        background: 'none',
        width: '100%'
    })
};

function DateTimeInputField({ label = '', ...props }) {
    const classes = useStyles();
    const [field, meta, helpers] = useField(props);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={classes.container}>
                {/* <label htmlFor={props.name}> */}
                {/*    <Typography variant="body2">{label}</Typography> */}
                {/* </label> */}
                <MobileDatePicker
                    renderInput={(props) => (
                        <TextField
                            size="small"
                            {...props}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end"> <CalendarToday /></InputAdornment>,
                            }}
                        />
                    )}
                    label={label}
                    inputFormat='dd/MM/yyyy'
                    {...field}
                    onChange={(value) => helpers.setValue(value)}
                    {...props}
                />
                {/* <SelectInput */}
                {/*    id={props.name} */}
                {/*    styles={selectStyles} */}
                {/*    {...field} */}
                {/*    onChange={helpers.setValue} */}
                {/*    // defaultValue={field.value ?? null} */}
                {/*    // value={field.value ?? null} */}
                {/*    getOptionLabel={(option) => option[labelKey]} */}
                {/*    getOptionValue={(option) => option[valueKey]} */}
                {/*    value={fieldValue} */}
                {/*    {...props} */}
                {/* /> */}
                <Typography className={classes.error} variant='caption'>
                    {meta.touched && !!meta.error ? meta.error : '  '} &nbsp;
                </Typography>
            </div>
        </LocalizationProvider>
    );
}

export default DateTimeInputField;

import React, { useCallback } from 'react';
import { Button, Grid } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import appModules from '../../appModules';
import SelectField from '../../libs/formik_fields/SelectField';
import DateInputField from '../../libs/formik_fields/DateInputField';
import { complaintSegmentOptionsList, complaintStatusOptionsList } from '../../services/complaints';
import useFetchSelectOptions from '../../hooks/useFetchSelectOptions';
import { getActiveUsersListForJobCardProcess } from '../../services/job_card';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';

const emptyComplaint = {
    segment: null,
    name: '',
    contactNo: '',
    address: '',
    complaintDescription: '',
    followupDate: new Date(),
    assignTo: null,
    status: null,
    remark: ''
};

const complaintSchema = yup.object().shape({
    segment: yup.mixed().required('Please select segment.'),
    name: yup.string().required('Please enter name.'),
    contactNo: yup.string().required('Please enter contact Number.'),
    address: yup.string().required('Please enter address.'),
    complaintDescription: yup.string().required('Please enter description.'),
    followupDate: yup.mixed().required('Please enter followup date.'),
    assignTo: yup.mixed().required('Please select assign to.'),
    status: yup.mixed().required('Please select status.'),
    remark: yup.string('Please enter valid remark.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const ComplaintFormContent = (formik) => {
    const { isSubmitting, values, errors } = formik;
    console.log('-> errors', errors);

    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(appUrls.COMPLAINT);
    }, [navigate]);

    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.COMPLAINT });

    const { options: activeOperatorsOptions, loading: activeOperatorsOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = payloadCreator({});
            return getActiveUsersListForJobCardProcess(payload);
        }
    });

    return (
        <Grid container>
            <Grid item xs={12} md={6} px={1}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <SelectField
                            name="segment"
                            placeholder="Segment"
                            valueKey="id"
                            labelKey="segment"
                            options={complaintSegmentOptionsList}
                        />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="name" label="Name*" />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="contactNo" label="Contact No*" />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="address" label="Address*" multiline rows={2.5} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="complaintDescription" label="Complaint Description*" multiline rows={2.5} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <SelectField
                            name="assignTo"
                            placeholder="Assigned To"
                            valueKey="id"
                            labelKey="name"
                            options={activeOperatorsOptions}
                        />
                    </Grid>

                    <Grid itemx xs={12}>
                        <SelectField
                            name="status"
                            placeholder="Status"
                            valueKey="id"
                            labelKey="value"
                            options={complaintStatusOptionsList}
                        />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="remark" label="Status Remark" />
                    </Grid>

                    <Grid itemx xs={12}>
                        <DateInputField name="followupDate" label="Followup Date*" fullWidth />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} pt={2}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            loading={isSubmitting}
                            loadingPosition="center"
                        >
                            Save
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                                ml: 1
                            }}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

function ComplaintForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyComplaint}
            onSubmit={handleSubmit}
            validationSchema={complaintSchema}
            FormContent={ComplaintFormContent}
        />
    );
}

export default ComplaintForm;

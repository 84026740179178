import React from 'react';
import Select from 'react-select';
import { selectStyles } from '../../../libs/formik_fields/SelectField';

function SelectFilter({ listProps, options, name, label, labelKey = 'label', valueKey = 'value',isMulti,isClearable=true  }) {
    return (
        <Select
            styles={selectStyles}
            options={options}
            value={listProps?.search?.[name]}
            onChange={(val) => listProps.setSearch({ [name]: val })}
            placeholder={`Select ${label}`}
            label={label}
            getOptionLabel={(option) => option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            isMulti={!!isMulti}
            isClearable={!!isClearable}
        />
    );
}

export default SelectFilter;

import { callApi } from '../utils/callApi';
import { API_BASE_URL } from './index';

export const getCommercialCustomersListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/commercialcustomer/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getCommercialCustomerPurchaseListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/commercialcustomer/pruchaselist`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const changeCommercialCustomerSmsStatusRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/commercialcustomer/sendsms/changestatus`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });

export const deleteCommercialCustomer = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/commercialcustomer/delete`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });

import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { changePasswordRequest } from 'services/auth';
import { encryptPassword } from 'utils';
import Breadcrumb from 'components/ui/Breadcrumb';
import ChangePasswordForm from '../../../components/auth/ChangePasswordForm';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}));

function ChangePasswordPage(props) {
    const classes = useStyles();

    const navigate = useNavigate();

    const handleChangePassword = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { currentPassword, password, confirmPassword } = values;
        const payload = {
            currentPassword: encryptPassword(currentPassword),
            password: encryptPassword(password),
            confirmPassword: encryptPassword(confirmPassword)
        };
        const response = await changePasswordRequest(payload);
        console.log('-> response', response);
        if (response.error === false) {
            resetForm();
            toast.success('Password changed successfully');
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Change Password" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <ChangePasswordForm handleSubmit={handleChangePassword} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ChangePasswordPage;

import React, { useCallback } from 'react';
import { Button, Grid } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import SwitchField from 'libs/formik_fields/SwitchField';
import appUrls from 'appUrls';

const emptyTyreSize = {
    size: '',
    description: '',
    status: true
};

const tyreSizeSchema = yup.object().shape({
    size: yup.string("Please enter size of tire.").required('Please enter size of tire.'),
    address: yup.string('Please enter valid description.'),
    status: yup.mixed().required('Status is required.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const TyreSizeFormContent = ({ isSubmitting, values }) => {

    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(appUrls.TYRE_SIZE);
    }, [navigate]);

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField name="size" label="Size*" />
                <TextField name="description" label="Description*" rows={4.1} multiline />
                {values.editMode && (
                    <SwitchField name="status" label="Status" />
                )}
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                >
                    Save
                </LoadingButton>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        ml: 1
                    }}
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Grid>
        </Grid>
    );
};

function TyreSizeForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyTyreSize}
            onSubmit={handleSubmit}
            validationSchema={tyreSizeSchema}
            FormContent={TyreSizeFormContent}
        />
    );
}

export default TyreSizeForm;

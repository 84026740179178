import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from 'react-select';


const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop:2,
        marginBottom: 4
        // marginBottom: theme.spacing(1),
    },
    error: {
        color: theme.palette.error.main
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 1000
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: 12,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 8,
        paddingRight: 8,
        background: 'none'
    })
};

function SelectInput({ label = '', labelKey = 'name', valueKey = '_id',  ...props }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <label htmlFor={props.id}>
                <Typography variant="body2">{label}</Typography>
            </label>
            <Select
                styles={selectStyles}
                getOptionLabel={(option) => option[labelKey]}
                getOptionValue={(option) => option[valueKey]}
                {...props}
            />
        </div>
    );
}

export default SelectInput;
import React, { useState } from 'react';
import Breadcrumb from 'components/ui/Breadcrumb';
import { Box, Card, CardContent, Grid, LinearProgress, Pagination } from '@mui/material';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ListProvider from 'components/ui/ListProvider';
import DeleteButton from '../../components/ui/buttons/DeleteButton';
import { SwitchFieldInput } from '../../libs/formik_fields/SwitchField';
import {
    changeCommercialCustomerSmsStatusRequest,
    deleteCommercialCustomer,
    getCommercialCustomersListRequest
} from '../../services/commercial_customer';
import PurchaseHistoryModal from '../../components/commercial-customers/PurchaseHistoryModal';
import TextFilter from '../../components/ui/list-filters/TextFilter';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function CommercialCustomersPage(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({
        search: { name: '' },
        sortParams: {
            column: 'cdate',
            order: 'asc'
        }
    });

    const [mutation, setMutation] = useState({});

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.COMMERCIAL_CUSTOMERS });

    const columns = [
        {
            field: 'sno',
            headerName: 'SNo.',
            flex: 1,
            minWidth: 80
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 4,
            minWidth: 150
        },
        {
            field: 'mobile',
            headerName: 'Mobile No.',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'sendSms',
            headerName: 'Is Sms On',
            flex: 4,
            minWidth: 100,
            renderCell: ({ row }) => (
                <Box>
                    <SwitchFieldInput
                        checked={row.sendSMS}
                        onChange={async (e) => {
                            const checked = !!e?.target?.checked;
                            const payload = createPayload({ id: row.id, sendSMS: checked });
                            const result = await changeCommercialCustomerSmsStatusRequest(payload);
                            if (result.error === false) {
                                setMutation({});
                            }
                        }}
                    />
                </Box>
            )
        },
        {
            field: 'job_card_history',
            headerName: 'Purchase History',
            flex: 4,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box>
                    <PurchaseHistoryModal customer={row} />
                </Box>
            )
        },
        {
            field: 'id',
            flex: 3,
            headerName: 'Actions',
            cellClassName: 'text-center',
            minWidth: 140,
            renderCell: ({ row }) => (
                <Box>
                    <DeleteButton
                        // color="info"
                        size="small"
                        style={{ marginLeft: 16 }}
                        handleDelete={async () => {
                            const payload = createPayload({ id: row.id });
                            const result = await deleteCommercialCustomer(payload);
                            if (result.error === false) {
                                setMutation({});
                            }
                        }}
                        entityName="Customer"
                    />
                </Box>
            )
        }
    ];

    const fetchCommercialCustomersList = async (data) => {
        const payload = createPayload(data);
        return getCommercialCustomersListRequest(payload);
    };

    return (
        <>
            <Breadcrumb title="Commercial Customers" />
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ListProvider fetcher={fetchCommercialCustomersList} initialData={initialData} listMutation={mutation}>
                                {(listProps) => (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="start">
                                                <Grid item xs={12} sm={7} md={6} lg={4}>
                                                    <TextFilter listProps={listProps} label="Customer Name" name="name" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                p={1}
                                                style={{
                                                    // height: 500,
                                                    width: '100%'
                                                }}
                                            >
                                                <Box className={classes.progressBarContainer}>
                                                    {listProps.loading && <LinearProgress />}
                                                </Box>
                                                {listProps.listItems && !listProps.loading && (
                                                    <>
                                                        <DataGrid
                                                            rows={listProps.listItems}
                                                            columns={columns}
                                                            pagination={false}
                                                            paginationMode="server"
                                                            pageSize={listProps.limit}
                                                            page={listProps.page}
                                                            row
                                                            autoHeight
                                                            disableColumnMenu
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                mt: 2,
                                                                mb: 0,
                                                                pb: 0
                                                            }}
                                                        >
                                                            <Pagination
                                                                color="primary"
                                                                count={listProps.totalPages}
                                                                page={listProps.page}
                                                                onChange={(e, value) => {
                                                                    console.log('-> value', value);

                                                                    listProps.setPage(value);
                                                                }}
                                                            />
                                                        </Box>
                                                    </>
                                                )}
                                                {listProps.initialLoading && (
                                                    <Grid container spacing={1}>
                                                        {[...Array(12)].map((_, i) => (
                                                            <Grid key={i} item xs={12}>
                                                                <Skeleton variant="rect" height={40} />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </ListProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default CommercialCustomersPage;

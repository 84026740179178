// eslint-disable-next-line import/no-duplicates
import gtLogo from 'assets/images/logo_gt.png';
// eslint-disable-next-line import/no-duplicates
import defaultLogo from 'assets/images/logo.png';

const gtAssets = {
    logo: gtLogo,
    favicon: 'gt-favicon.jpg'
};

const defaultAssets = {
    logo: defaultLogo,
    favicon: 'favicon.jpg'
};

// eslint-disable-next-line import/prefer-default-export
export const getSiteAssets = (siteName) => {
    switch (siteName) {
        case 'GT':
            return gtAssets;
        default:
            return defaultAssets;
    }
};

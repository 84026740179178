import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, Pagination, TextField, Typography } from '@mui/material';
import useModalState from 'hooks/useModalState';
import { DataGrid } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import ListProvider from '../ui/ListProvider';

import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import { makeStyles } from '@mui/styles';
import { getCommercialCustomerPurchaseListRequest } from '../../services/commercial_customer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function CommercialCustomerPurchaseHistoryModal({ buttonText = 'Purchase History', customer }) {
    const { isOpen, handleClose, handleOpen } = useModalState(false);
    const classes = useStyles();

    const [initialData, setInitialData] = useState({
        search: {},
        sortParams: {
            column: 'cdate',
            order: 'asc'
        }
    });

    const [listMutation, setListMutation] = useState({});

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.CUSTOMERS });


    const columns = [
        {
            field: 'salesman',
            headerName: 'Salesman',
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.salesPerson}
                </Box>
            )
        },
        {
            field: 'segment',
            headerName: 'Segment',
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.segment?.segment}
                </Box>
            )
        },
        {
            field: 'tyre',
            headerName: 'Tyre',
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.tyre?.name}
                </Box>
            )
        },
        {
            field: 'tyreSize',
            headerName: 'Tyre Size',
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.tyreSize?.size}
                </Box>
            )
        },
        {
            field: 'billNumber',
            headerName: 'Bill Number',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'vehicleMake',
            headerName: 'Vehicle Make',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'saleDate',
            headerName: 'Sale Date',
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.salesDate}
                </Box>
            )
        },
        {
            field: 'listPrice',
            headerName: 'Vehicle Make',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'salePrice',
            headerName: 'Vehicle Make',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            flex: 4,
            minWidth: 100
        }
        // {
        //     field: 'id',
        //     flex: 3,
        //     headerName: 'Actions',
        //     cellClassName: 'text-center',
        //     minWidth: 140,
        //     renderCell: ({ row }) => (
        //         <Box>
        //             <EditButton
        //                 color="info"
        //                 size="small"
        //                 style={{ marginLeft: 16 }}
        //                 onClick={() => navigate(`${appUrls.INCENTIVE_ENTRY_EDIT}/${row.id}`)}
        //             />
        //             <DeleteButton
        //                 // color="info"
        //                 size="small"
        //                 style={{ marginLeft: 16 }}
        //                 handleDelete={async () => {
        //                     const payload = createPayload({ id: row.id });
        //                     const result = deleteIncentiveEntryRequest(payload);
        //                     if (result.error === false) {
        //                         setListMutation({});
        //                     }
        //                 }}
        //                 entityName="Incentive Entry"
        //             />
        //         </Box>
        //     )
        // }
    ];

    const fetchCommercialCustomerPurchaseHistory = async (data) => {
        const payload = createPayload({
            id: customer.id
        });
        const response = await getCommercialCustomerPurchaseListRequest(payload);
        console.log('-> response', response);
        const obj = {
            results: response,
            page: 1,
            totalPages: 1,
            totalResults: response.length,
            error: response.error
        };
        console.log('-> obj', obj);
        return obj;
    };

    return (
        <>
            <Button onClick={handleOpen} variant="outlined" color="primary">
                {buttonText}
            </Button>
            <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h3">Customer Purchase History</Typography>
                        <Button onClick={handleClose}>Close</Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        {/* <Grid item xs={12}> */}
                        {/*    <Grid container> */}
                        {/*        <Grid item xs={12} md={6}> */}
                        {/*            {' '} */}
                        {/*            <Typography variant="body">Customer Name : {customer?.name}</Typography>{' '} */}
                        {/*        </Grid> */}
                        {/*        <Grid item xs={12} md={6}> */}
                        {/*            {' '} */}
                        {/*            <Typography variant="body">Vehicle Registration No. : {customer?.vehicleRegNo}</Typography>{' '} */}
                        {/*        </Grid> */}
                        {/*        <Grid item xs={12} md={6}> */}
                        {/*            {' '} */}
                        {/*            <Typography variant="body"> */}
                        {/*                Vehicle Make : {customer?.vehicleDTO?.name}-{customer?.vehicleModelDTO?.name} */}
                        {/*            </Typography>{' '} */}
                        {/*        </Grid> */}
                        {/*    </Grid> */}
                        {/* </Grid> */}
                        {isOpen && (
                            <Grid item xs={12}>
                                <ListProvider
                                    fetcher={fetchCommercialCustomerPurchaseHistory}
                                    initialData={initialData}
                                    listMutation={listMutation}
                                >
                                    {(listProps) => (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box
                                                    p={1}
                                                    style={{
                                                        // height: 500,
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Box className={classes.progressBarContainer}>
                                                        {listProps.loading && <LinearProgress />}
                                                    </Box>
                                                    {listProps.listItems && !listProps.loading && (
                                                        <>
                                                            <DataGrid
                                                                rows={listProps.listItems}
                                                                columns={columns}
                                                                pagination={false}
                                                                paginationMode="server"
                                                                pageSize={listProps.limit}
                                                                page={listProps.page}
                                                                row
                                                                autoHeight
                                                                disableColumnMenu
                                                            />
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    mt: 2,
                                                                    mb: 0,
                                                                    pb: 0
                                                                }}
                                                            >
                                                                <Pagination
                                                                    color="primary"
                                                                    count={listProps.totalPages}
                                                                    page={listProps.page}
                                                                    onChange={(e, value) => {
                                                                        console.log('-> value', value);

                                                                        listProps.setPage(value);
                                                                    }}
                                                                />
                                                            </Box>
                                                        </>
                                                    )}
                                                    {listProps.initialLoading && (
                                                        <Grid container spacing={1}>
                                                            {[...Array(12)].map((_, i) => (
                                                                <Grid key={i} item xs={12}>
                                                                    <Skeleton variant="rect" height={40} />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </ListProvider>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default CommercialCustomerPurchaseHistoryModal;

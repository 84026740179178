import React, { useCallback, useEffect } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import SelectField from '../../libs/formik_fields/SelectField';
import StatusSwitchField from '../../libs/formik_fields/StatusSwitchField';
import { smsTemplatesTriggerOptionsList } from '../../services/sms_template';

const emptySmsTemplate = {
    smsTitle: null,
    smsText: '',
    status: 1,
    triggerPoint: null
};

const quotationSchema = yup.object().shape({
    smsTitle: yup.mixed().required('Please enter title.'),
    smsText: yup.string().required('Please enter text.'),
    status: yup.string().required('Please select status.'),
    triggerPoint: yup.mixed().required('Please select trigger point.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const SmsTemplateFormContent = (formik) => {
    const { isSubmitting, values, errors, setFieldValue } = formik;
    const { editMode } = values;

    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(appUrls.SMS_TEMPLATE_LIST);
    }, [navigate]);

    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} px={1}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField name="smsTitle" label="SMS Title*" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="smsText" label="SMS Text*" multiline rows={10} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <SelectField
                            name="triggerPoint"
                            placeholder="Trigger Point"
                            valueKey="id"
                            labelKey="trigerPoint"
                            options={smsTemplatesTriggerOptionsList}
                        />
                    </Grid>
                    <Grid itemx xs={12}>
                        <StatusSwitchField name="status" label="Status" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3">Variable List</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Customer Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;customer&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Vehicle Number</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;vnumber&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Your Company Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;cname&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Customer Mobile Number</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;mobile&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Vehicle Make</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;vmake&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Revisit Odometer Reading</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;odometerreading&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Close Without Bill Reason 1</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;cwobr_1&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Close Without Bill Reason 2</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;cwobr_2&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Close Without Bill Reason 3</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;cwobr_3&gt;</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">Close Without Bill Reason 4</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">&lt;cwobr_4&gt;</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} pt={2}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            loading={isSubmitting}
                            loadingPosition="center"
                        >
                            Save
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                                ml: 1
                            }}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

function SmsTemplateForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptySmsTemplate}
            onSubmit={handleSubmit}
            validationSchema={quotationSchema}
            FormContent={SmsTemplateFormContent}
        />
    );
}

export default SmsTemplateForm;

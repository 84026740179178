const appServices = {
    TYRE_TUBE_FITMENT: 'tyre_tube_fitment',
    WHEEL_ALIGNMENT: 'wheel_alignment',
    N2: 'n_2',
    TYRE_REPAIR: 'tyre_repair',
    WHEEL_BALANCING: 'wheel_balancing',
    WHEEL_BALANCING_WEIGHT: 'wheel_balancing_weight',
    TYRE_STOCK_MANAGEMENT: 'tyre_stock_mgmt',
    OTHER_PRODUCT_SALES: 'other_product_sales',
    NEW_TYRE_FITMENT: 'new_tyre_fitment',
};
export default appServices;
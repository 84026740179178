import React, { useMemo } from 'react';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';
import _ from 'lodash';

const statusEnum = {
    ACTIVE: 1,
    INACTIVE: 2,
    DELETED: 3
};

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2
    }
}));

export const SwitchFieldInput = React.memo(
    (props) => <Android12Switch inputProps={{ 'aria-label': 'controlled' }} {...props} />,
    (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);

function StatusSwitchField({ label, showErrorText = false, center, ...props }) {
    const [{ value, ...field }, meta, helpers] = useField(props);

    const switchValue = useMemo(() => value === statusEnum.ACTIVE, [value]);

    const handleValueChange = (event) => {
        const value = event.target.checked ? statusEnum.ACTIVE : statusEnum.INACTIVE;
        helpers.setValue(value);
    };

    return (
        <FormGroup
            sx={{
                pl: center ? 0 : 2,
                justifyContent: center ? 'center' : 'flex-start',
                alignItems: center ? 'center' : 'flex-start'
            }}
        >
            {label ? (
                <FormControlLabel
                    control={
                        <SwitchFieldInput
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={switchValue}
                            {...field}
                            onChange={handleValueChange}
                            {...props}
                        />
                    }
                    label={label}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse'
                    }}
                />
            ) : (
                <SwitchFieldInput
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={switchValue}
                    {...field}
                    onChange={handleValueChange}
                    {...props}
                />
            )}

            {showErrorText && <Typography variant="caption">{meta.touched && !!meta.error ? meta.error : '  '} &nbsp;</Typography>}
        </FormGroup>
    );
}

export default React.memo(StatusSwitchField);

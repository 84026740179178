import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export
export const saveSMSIncentiveSetupRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentivesetup/save`, payload),
        successCode: 200
    });

export const getSMSIncentiveSetupListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentivesetup/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getSMSIncentiveSetupDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentivesetup/get`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const smsIncentiveSegmentOptionsList = [
    { id: '1', segment: 'Car Tyre' },
    { id: '2', segment: 'Two Wheeler' },
    { id: '3', segment: 'Three Wheeler' },
    { id: '4', segment: 'LCV' },
    { id: '5', segment: 'SCV' },
    { id: '6', segment: 'HCV' },
    { id: '7', segment: 'OTR' }
];

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import { FieldArray } from 'formik';
import StatusField from '../../libs/formik_fields/StatusField';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import useFetchSelectOptions from '../../hooks/useFetchSelectOptions';
import { getAllUserMenusListRequest } from '../../services/user';
import OperatorMenuItem from './OperatorMenuItem';
import OperatorMenuItemCheckBox from './OperatorMenuItemCheckBox';
import StatusSwitchField from '../../libs/formik_fields/StatusSwitchField';

const emptyOperator = {
    userName: '',
    password: '',
    name: '',
    mobile: '',
    email: '',
    address: '',
    status: 1,
    menus: []
};

const operatorSchema = yup.object().shape({
    userName: yup.string().required('Please enter username of operator.'),
    password: yup.string().nullable(),
    name: yup.string().required('Please enter name of operator.'),
    mobile: yup.string().required('Please enter mobile number of operator.'),
    email: yup.string().required('Please enter email of operator.'),
    address: yup.string('Please enter valid address.'),
    status: yup.mixed().required('Status is required.'),
    menus: yup.array().min(1, 'Please add at least one model item.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const OperatorFormContent = ({ errors, isSubmitting, values, setFieldValue }) => {
    console.log('-> errors', errors);

    const navigate = useNavigate();

    const [operatorPayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.OPERATOR_MANAGEMENT });

    const { options: allMenuOptions, loading: menuOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = operatorPayloadCreator({});
            return getAllUserMenusListRequest(payload);
        }
    });

    useEffect(() => {
        if (!values?.menus?.length || values?.menus?.length < 1) {
            setFieldValue('menus', allMenuOptions);
        }
    }, [allMenuOptions]);

    const handleBack = useCallback(() => {
        navigate(appUrls.OPERATOR_MANAGEMENT);
    }, [navigate]);

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField name="userName" label="Username*" disabled={values.editMode} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField type="password" name="password" label="Password*" autoComplete="new-password" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField name="name" label="Name*" />
                <TextField name="mobile" label="Mobile*" />
                <TextField name="email" label="Email*" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField name="address" label="Address" rows={3.9} multiline />
                {values.editMode && <StatusSwitchField name="status" label="Status" />}
            </Grid>
            <Grid item xs={12}>
                <FieldArray
                    name="menus"
                    render={(arrayHelpers) => (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography m={0} variant="h3">
                                    Access List
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        {/* <SelectInput */}
                                        {/*    id="tyreSize" */}
                                        {/*    placeholder="Size" */}
                                        {/*    valueKey="id" */}
                                        {/*    labelKey="size" */}
                                        {/*    options={tyreSizeOptionsList} */}
                                        {/*    onChange={setSelectedSize} */}
                                        {/*    value={selectedSize} */}
                                        {/*    menuPlacement="top" */}
                                        {/* /> */}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        {/* <Button */}
                                        {/*    variant="outlined" */}
                                        {/*    color="primary" */}
                                        {/*    sx={{ */}
                                        {/*        ml: 1 */}
                                        {/*    }} */}
                                        {/*    onClick={() => { */}
                                        {/*        arrayHelpers.push({ */}
                                        {/*            menu: null, */}
                                        {/*            submenus: [] */}
                                        {/*        }); */}
                                        {/*    }} */}
                                        {/* > */}
                                        {/*    Add */}
                                        {/* </Button> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} pb={2}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sno</TableCell>
                                            <TableCell align="center">Menu</TableCell>
                                            <TableCell align="center">Submenus</TableCell>
                                            {/* <TableCell align="center">Status</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {values.menus.map((row, index) => (
                                            <OperatorMenuItemCheckBox index={index} item={row} allMenuOptions={allMenuOptions} />
                                        ))}
                                        {values.menus.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    Please add at least one permission
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    )}
                />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                >
                    Save
                </LoadingButton>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        ml: 1
                    }}
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Grid>
        </Grid>
    );
};

function OperatorForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyOperator}
            onSubmit={handleSubmit}
            validationSchema={operatorSchema}
            FormContent={OperatorFormContent}
        />
    );
}

export default OperatorForm;

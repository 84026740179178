import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import ComplaintForm from '../../components/complaint/ComplaintForm';
import { getComplaintDetailsRequest, saveComplaintRequest } from '../../services/complaints';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ComplaintCommentHistoryList from '../../components/complaint/ComplaintCommentHistoryList';
import { getQuotationDetailsRequest, saveQuotationRequest } from '../../services/quotation';
import QuotationForm from '../../components/quotation/QuotationForm';
import QuotationCommentHistoryList from '../../components/quotation/QuotationCommentHistoryList';
import OrderCommentHistoryList from '../../components/order/OrderCommentHistoryList';
import { getOrderDetailsRequest, saveOrderRequest } from '../../services/order';
import OrderForm from '../../components/order/OrderForm';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function OrderEditPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.COMPLAINT });
    const { orderId } = useParams();

    const [orderDetails, setOrderDetails] = useState();
    const [orderDetailsLoading, setOrderDetailsLoading] = useState(true);

    useEffect(() => {
        async function fetchOrderDetails() {
            setOrderDetailsLoading(true);
            const payload = payloadCreator({ id: orderId });
            const result = await getOrderDetailsRequest(payload);
            if (result.error === false) {
                const { error, ...data } = result;
                setOrderDetails({
                    ...data,
                    followupDate: moment(result.followupDate, 'DD/MM/YYYY').toDate(),
                    editMode: true
                });
            }
            setOrderDetailsLoading(false);
            console.log('-> result', result);
        }

        fetchOrderDetails();
    }, [orderId]);

    const navigate = useNavigate();

    const handleEditOrder = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { segment, name, contactNo, orderDescription, address, assignTo, followupDate, remark, status } = values;
        const data = {
            id: orderId,
            segment,
            name,
            contactNo,
            orderDescription,
            followupDate: moment(followupDate).format('DD-MM-YYYY'),
            address,
            assignTo,
            remark,
            status
        };
        const payload = payloadCreator(data);
        const response = await saveOrderRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.ORDER);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Order" />
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {orderDetailsLoading || <OrderForm initialValues={orderDetails} handleSubmit={handleEditOrder} />}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <OrderCommentHistoryList orderId={orderId} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default OrderEditPage;

import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export
export const saveUserRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/user/save`, payload),
        successCode: 200
    });

export const getUserListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/user/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getUserDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/user/get`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getAllUserMenusListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/user/allmenu`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

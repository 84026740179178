import { Button, Table, TableCell, tableCellClasses, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';
import TextField from '../../libs/formik_fields/TextField';
import { FieldArray, useFormikContext } from 'formik';
import FormikFormWrapper from '../../libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 'none'
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    // hide last border
    '&:last-child': {
        border: 0
    }
}));

function RateListFormContent() {
    const formik = useFormikContext();

    const rateItems = useMemo(() => formik.values.rates.filter(item=>item.code !== "other_product_sales"), [formik.values.rates]);
    console.log("-> rateItems", rateItems);

    return (
        <FieldArray
            name="rates"
            render={(arrayHelpers) => (
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    {rateItems.map((item, index) => (
                        <StyledTableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell component="th" scope="row">
                                {index + 1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                                {item.label}
                            </StyledTableCell>
                            {item.subComponentExist || (
                                <StyledTableCell component="th" scope="row">
                                    <TextField type="number" name={`rates[${index}].value`} showErrorText={false} />
                                </StyledTableCell>
                            )}
                            {item.subComponentExist && (
                                <>
                                    {item.subComponent.map((subItem, subIndex) => (
                                        <StyledTableCell key={subIndex} component="th" scope="row">
                                            <TextField
                                                type="number"
                                                label={subItem.label}
                                                name={`rates[${index}].subComponent[${subIndex}].value`}
                                                showErrorText={false}
                                            />
                                        </StyledTableCell>
                                    ))}
                                </>
                            )}
                        </StyledTableRow>
                    ))}
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                loading={false}
                                loadingPosition="center"
                            >
                                Save
                            </LoadingButton>
                        </StyledTableCell>
                    </StyledTableRow>
                </Table>
            )}
        />
    );
}

function RateListForm({ rateListItems = [], onSubmit, onCancel }) {
    return (
        <FormikFormWrapper
            initialValues={{
                rates: rateListItems
            }}
            onSubmit={onSubmit}
            FormContent={RateListFormContent}
        />
    );
}

export default RateListForm;

const appModules = {
    TYRE_BRAND: 'tyrebrand',
    TYRE_SIZE: 'tyresize',
    TYRE: 'tyres',
    VEHICLE: 'vehicle',
    OPERATOR_MANAGEMENT: 'operatormgmt',
    SERVICE_SETUP: 'servicesetup',
    INCENTIVE_TARGET: 'incentivetarget',
    JOB_CARD: 'jobcard',
    BILLING: 'billing',
    BILL_TEMPLATE: 'billtemplate',
    COMPLAINT: 'complaint',
    CUSTOMERS: 'customers',
    COMMERCIAL_CUSTOMERS: 'commercialcustomer',
    QUOTATION: 'quotation',
    ORDER: 'order',
    SMS_TEMPLATE: 'smstemplate',
    SEND_SMS: 'sendsms',
    INCENTIVE_SETUP: 'incentivesetup',
    INCENTIVE_ENTRY: 'incentiveentry',
    DASHBOARD: 'dashboard',
    REPORT1: 'report1'
};

export default appModules;

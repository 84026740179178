import React, {useEffect, useState} from 'react';

function useFetchSelectOptions({fetcher}) {
    const [options,setOptions] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(){
            setLoading(true);
            const data = await fetcher();
            if(data.error === false){
                setOptions(data);
            }
            setLoading(false);
        }
        fetchData()
    }, []);

    return {options,loading};

}

export default useFetchSelectOptions;
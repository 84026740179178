import React from 'react';
import { gridSpacing } from '../../store/constant';
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Breadcrumb({ title }) {
    const theme = useTheme();
    return (
        <Card
            sx={{
                marginBottom: 2,
                border: '1px solid',
                borderColor: theme.palette.primary[200] + 75,
                background: theme.palette.background.default
            }}
        >
            <Box sx={{ p: 1.5 }}>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                        <Typography variant="h3" sx={{ fontWeight: 500 }}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

export default Breadcrumb;

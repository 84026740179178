import React from 'react';
// import { Exce as EditIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ExcelIconSvg from 'assets/images/ms_excel_icon.svg';

function EditButton(props) {
    return (
        <IconButton  {...props}>
            <img height="24px" width="24px" src={ExcelIconSvg} alt="" />
        </IconButton>
    );
}

export default EditButton;

import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';
import moment from 'moment';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import { saveSMSIncentiveSetupRequest } from 'services/sms_incentive_setup';
import IncentiveSetupForm from 'components/insentive-setup/InsentiveSetupForm';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddIncentiveSetupPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.INCENTIVE_SETUP });

    const navigate = useNavigate();

    const handleAddSmsIncentive = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { segment, tyre, tyreSize, maxDiscount, incentive, status, timeltOffer, sDate, eDate } = values;
        const data = {
            segment,
            tyre,
            tyreSize,
            maxDiscount,
            incentive,
            timeltOffer,
            sDate,
            eDate,
            status: {
                id: status
            }
        };
        data.sDate = moment(data?.sDate).format('DD-MM-YYYY');
        data.eDate = moment(data?.eDate).format('DD-MM-YYYY');
        const payload = payloadCreator(data);
        const response = await saveSMSIncentiveSetupRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.INCENTIVE_SETUP_LIST);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Add Incentive" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <IncentiveSetupForm handleSubmit={handleAddSmsIncentive} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddIncentiveSetupPage;

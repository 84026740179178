import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { extractValue } from 'utils';
import appUrls from 'appUrls';
import Breadcrumb from 'components/ui/Breadcrumb';
import TyreBrandForm from 'components/tyre-brand/TyreBrandForm';
import { getTyreBrandDetailsRequest, saveTyreBrandRequest } from 'services/tire_brand';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import TyreSizeForm from '../../../components/tyre-size/TyreSizeForm';
import { getTyreSizeDetailsRequest, saveTyreSizeRequest } from '../../../services/tyre_size';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}));

// eslint-disable-next-line no-unused-vars
function EditTyreSizePage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [tyreSizePayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.TYRE_SIZE });

    const navigate = useNavigate();

    const { tyreSizeId } = useParams();
    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            setDetailsLoading(true);
            const detailsReqPayload = tyreSizePayloadCreator({ id: tyreSizeId });
            const data = await getTyreSizeDetailsRequest(detailsReqPayload);
            if (data.error === false) {
                const detailsObj = data;
                detailsObj.status = detailsObj.status.status === 'ACTIVE';
                detailsObj.editMode = true;
                setDetails(detailsObj);
            }
            setDetailsLoading(false);
        };
        fetchDetails();
    }, [tyreSizeId]);

    const handleTyreSize = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { size, description, status } = values;
        const data = {
            id: tyreSizeId,
            size,
            description,
            status: {
                id: status ? '1' : '2'
            }
        };
        const reqPayload = tyreSizePayloadCreator(data);
        const response = await saveTyreSizeRequest(reqPayload);
        console.log('-> response', response);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.TYRE_SIZE);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Tyre Size" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {!detailsLoading && details ? (
                                            <TyreSizeForm initialValues={details} handleSubmit={handleTyreSize} />
                                        ) : null}

                                        {detailsLoading && (
                                            <Grid container spacing={3}>
                                                {[...Array(8)].map((_, index) => (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <Skeleton variant="rect" height={40} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EditTyreSizePage;

import React from 'react';
import { TextField } from '@mui/material';

function TextFilter({listProps, name, label}) {
    return (
        <TextField
            id={name}
            label={label}
            type="search"
            variant="outlined"
            size="small"
            value={listProps.search[name]}
            onChange={(e) =>
                listProps.setSearch({ [name]: e.target.value })
            }
            fullWidth
        />
    );
}

export default TextFilter;
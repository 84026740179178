import { Link } from 'react-router-dom';

// material-ui
import { Box, ButtonBase } from '@mui/material';

// project imports
import logoImg from 'assets/images/logo-dark.svg';
import appUrls from '../../../appUrls';
import { getSiteAssets } from '../../../assetProvider';
import { useSelector } from 'react-redux';
import { selectBuildSite } from '../../../store/slices/authSlice';
import { useMemo } from 'react';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const siteName = useSelector(selectBuildSite);
    const siteAssets = useMemo(() => getSiteAssets(siteName), [siteName]);

    return (
        <Box
            disableRipple
            component={Link}
            to={appUrls.HOME}
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <img
                src={siteAssets.logo}
                alt=""
                style={{
                    // width: '4.5rem',
                    // margin: '0 auto'
                    // width: 130,
                    height: '2.5rem',
                    width: 'auto'
                }}
            />
            {/* <Logo /> */}
        </Box>
    );
};

export default LogoSection;

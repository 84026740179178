import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import { getComplaintCommentHistoryRequest } from '../../services/complaints';
import ListProvider from '../ui/ListProvider';
import { Box, Grid, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { getQuotationCommentHistoryRequest } from '../../services/quotation';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
    },
    progressBarContainer: {
        height: 5
    }
}));

function QuotationCommentHistoryList({ quotationId }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({
        search: { name: '', status: { id: 1 } },
        sortParams: {
            column: 'name',
            order: 'asc'
        }
    });

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.QUOTATION });

    const columns = [
        {
            field: 'sno',
            headerName: 'Sno',
            flex: 4,
            minWidth: 150
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'remark',
            headerName: 'Comment',
            flex: 4,
            minWidth: 150
        },
        {
            field: 'remarkDate',
            headerName: 'Date',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'remarkBy',
            headerName: 'Operator',
            flex: 4,
            minWidth: 100
        }
    ];

    const fetchCommentsList = async () => {
        const payload = createPayload({ id: quotationId });
        const response = await getQuotationCommentHistoryRequest(payload);
        return {
            error: response.error,
            results: response,
            page: 1,
            limit: 15,
            totalPages: 1,
            totalResults: response.length
        };
    };

    return (
        <Grid item xs={12}>
            <ListProvider fetcher={fetchCommentsList} initialData={initialData}>
                {(listProps) => (
                    <Grid container>
                        <Grid item xs={12}>
                            <Box
                                p={1}
                                style={{
                                    // height: 500,
                                    width: '100%'
                                }}
                            >
                                <Box className={classes.progressBarContainer}>{listProps.loading && <LinearProgress />}</Box>
                                {listProps.listItems && !listProps.loading && (
                                    <>
                                        <DataGrid
                                            rows={listProps.listItems}
                                            columns={columns}
                                            pagination={false}
                                            paginationMode="server"
                                            pageSize={listProps.limit}
                                            page={listProps.page}
                                            row
                                            autoHeight
                                            disableColumnMenu
                                        />
                                    </>
                                )}
                                {listProps.initialLoading && (
                                    <Grid container spacing={1}>
                                        {[...Array(12)].map((_, i) => (
                                            <Grid key={i} item xs={12}>
                                                <Skeleton variant="rect" height={40} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </ListProvider>
        </Grid>
    );
}

export default QuotationCommentHistoryList;

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appUrls from 'appUrls';
import Breadcrumb from 'components/ui/Breadcrumb';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import OperatorForm from 'components/operator/OperatorForm';
import { getUserDetailsRequest, saveUserRequest } from 'services/user';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}));

// eslint-disable-next-line no-unused-vars
function EditOperatorPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [operatorPayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.OPERATOR_MANAGEMENT });

    const navigate = useNavigate();

    const { operatorId } = useParams();
    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            setDetailsLoading(true);
            const detailsReqPayload = operatorPayloadCreator({ id: operatorId });
            const data = await getUserDetailsRequest(detailsReqPayload);
            console.log('-> data', data);
            if (data.error === false) {
                const detailsObj = data;
                detailsObj.status = detailsObj.status.id;
                const formattedMenus = [];
                detailsObj.menus.forEach((menu) => {
                    if (menu.hasAccess) {
                        const obj = {};
                        obj.menu = menu;
                        const selectedSubmenus = [];
                        if (menu.submenus && Array.isArray(menu.submenus)) {
                            menu.submenus.forEach((submenu) => {
                                if (submenu.hasAccess) {
                                    selectedSubmenus.push(submenu);
                                }
                            });
                        }
                        obj.submenus = selectedSubmenus;
                        formattedMenus.push(obj);
                    }
                });
                // detailsObj.menus = formattedMenus;
                detailsObj.editMode = true;
                setDetails(detailsObj);
            }
            setDetailsLoading(false);
        };
        fetchDetails();
    }, [operatorId]);

    const handleOperatorUpdate = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { password = '', name, email, mobile, address, status, menus = [] } = values;
        const formattedMenus = [];
        menus.forEach((menu) => {
            if (menu && menu.hasAccess && menu.id) {
                formattedMenus.push({ id: menu.id });
                if (menu.submenus && menu.submenus.length) {
                    menu.submenus.forEach((submenu) => {
                        if (submenu && submenu.hasAccess && submenu.id) {
                            formattedMenus.push({ id: submenu.id });
                        }
                    });
                }
            }

        });
        console.log("-> updateOperator formattedMenus", formattedMenus);

        const data = {
            id: operatorId,
            userName: details.userName,
            password: password ?? '',
            name,
            email,
            mobile,
            address,
            status: {
                id: status ? '1' : '2'
            },
            menus: formattedMenus
        };
        console.log("-> updateOperator data", data);
        const reqPayload = operatorPayloadCreator(data);
        const response = await saveUserRequest(reqPayload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.OPERATOR_MANAGEMENT);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Operator" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {!detailsLoading && details ? (
                                            <OperatorForm initialValues={details} handleSubmit={handleOperatorUpdate} />
                                        ) : null}

                                        {detailsLoading && (
                                            <Grid container spacing={3}>
                                                {[...Array(8)].map((_, index) => (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <Skeleton variant="rect" height={40} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EditOperatorPage;

import React, { useCallback } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import isEqual from 'react-fast-compare';
import useFieldFast from './useFieldFast';

export const CheckBoxFieldInput = React.memo((props) => <Checkbox inputProps={{ 'aria-label': 'controlled' }} {...props} />, isEqual);

function CheckBoxField({ label, showErrorText = false, ...props }) {
    const [{ value, ...field }, meta, helpers] = useFieldFast(props);

    const handleCheckedChange = useCallback((e) => {
        helpers.setValue(e.target.checked);
    }, []);

    return (
        <FormGroup
            sx={{
                pl: 0.5
            }}
        >
            <FormControlLabel
                control={
                    <CheckBoxFieldInput
                        size="small"
                        inputProps={{ 'aria-label': 'controlled' }}
                        {...field}
                        onChange={handleCheckedChange}
                        sx={{ padding: '6px' }}
                        {...props}
                        checked={!!value}
                    />
                }
                label={label}
            />
            {showErrorText && <Typography variant="caption">{meta.touched && !!meta.error ? meta.error : '  '} &nbsp;</Typography>}
        </FormGroup>
    );
}

export default React.memo(CheckBoxField);

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appUrls from 'appUrls';
import Breadcrumb from 'components/ui/Breadcrumb';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import { getIncentiveEntryDetailsRequest, saveIncentiveEntryRequest } from '../../services/incentive_entry';
import IncentiveEntryForm from '../../components/incentive-entry/IncentiveEntryForm';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}));

// eslint-disable-next-line no-unused-vars
function EditIncentiveEntryPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.INCENTIVE_ENTRY });

    const navigate = useNavigate();

    const { incentiveEntryId } = useParams();
    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            setDetailsLoading(true);
            const detailsReqPayload = payloadCreator({ id: incentiveEntryId });
            const data = await getIncentiveEntryDetailsRequest(detailsReqPayload);
            console.log('-> data', data);
            if (data.error === false) {
                const detailsObj = data;
                detailsObj.editMode = true;
                setDetails(detailsObj);
            }
            setDetailsLoading(false);
        };
        fetchDetails();
    }, [incentiveEntryId]);

    const handleIncentiveEntrySave = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { id, segment, tyre, tyreSize, customer, listPrice, salePrice, quantity, vehicleMake, billNumber } = values;
        const data = {
            id,
            segment,
            tyre,
            tyreSize,
            customer,
            listPrice,
            salePrice,
            quantity,
            vehicleMake,
            billNumber
        };
        const payload = payloadCreator(data);
        const response = await saveIncentiveEntryRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.INCENTIVE_ENTRY_LIST);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Incentive Entry" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {!detailsLoading && details ? (
                                            <IncentiveEntryForm initialValues={details} handleSubmit={handleIncentiveEntrySave} />
                                        ) : null}

                                        {detailsLoading && (
                                            <Grid container spacing={3}>
                                                {[...Array(8)].map((_, index) => (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <Skeleton variant="rect" height={40} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EditIncentiveEntryPage;

import React from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import swal from '@sweetalert/with-react';

function EditButton({ handleDelete, entityName = 'item', ...props }) {
    const confirmDelete = async () => {
        const confirmed = await swal({
            title: 'Are you sure!?',
            description: `Process further will permanently delete this ${entityName}.`,
            icon: 'warning',
            buttons: true,
            dangerMode: true
        });
        if (confirmed) {
            handleDelete();
        }
    };

    return (
        <IconButton color="error" aria-label="delete" {...props} onClick={confirmDelete}>
            <DeleteIcon />
        </IconButton>
    );
}

export default EditButton;

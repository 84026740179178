import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import ComplaintForm from '../../components/complaint/ComplaintForm';
import { getComplaintDetailsRequest, saveComplaintRequest } from '../../services/complaints';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ComplaintCommentHistoryList from '../../components/complaint/ComplaintCommentHistoryList';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function ComplaintEditPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.COMPLAINT });
    const { complaintId } = useParams();

    const [complaintDetails, setComplaintDetails] = useState();
    const [complaintStateLoading, setComplaintStateLoading] = useState(true);

    useEffect(() => {
        async function fetchComplaintDetails() {
            setComplaintStateLoading(true);
            const payload = payloadCreator({ id: complaintId });
            const result = await getComplaintDetailsRequest(payload);
            if (result.error === false) {
                const { error, status, remark, ...data } = result;
                setComplaintDetails({
                    ...data,
                    followupDate: moment(result.followupDate, 'DD/MM/YYYY').toDate(),
                    editMode: true
                });
            }
            setComplaintStateLoading(false);
            console.log('-> result', result);
        }

        fetchComplaintDetails();
    }, [complaintId]);

    const navigate = useNavigate();

    const handleEditComplaint = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { id, segment, name, contactNo, address, complaintDescription, followupDate, assignTo, status, remark } = values;
        const data = {
            id,
            segment,
            name,
            contactNo,
            address,
            complaintDescription,
            followupDate: moment(followupDate).format('DD-MM-YYYY'),
            assignTo,
            status,
            remark
        };
        const payload = payloadCreator(data);
        const response = await saveComplaintRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.COMPLAINT);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Complaint" />
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {complaintStateLoading || (
                                            <ComplaintForm initialValues={complaintDetails} handleSubmit={handleEditComplaint} />
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <ComplaintCommentHistoryList complaintId={complaintId} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ComplaintEditPage;

import React, { useState } from 'react';
import _ from 'lodash';
import Breadcrumb from 'components/ui/Breadcrumb';
import { Box, Card, CardContent, Grid, LinearProgress, Pagination } from '@mui/material';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ListProvider from 'components/ui/ListProvider';
import { getSendSmsListRequest, smsStatusOptions } from '../../../services/send_sms';
import SelectFilter from '../../../components/ui/list-filters/SelectFilter';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function SendSmsListPage(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({
        search: {
            msgStatus: 'PENDING'
        },
        sortParams: {
            column: 'cdate',
            order: 'asc'
        }
    });

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.SEND_SMS });

    const columns = [
        {
            field: 'smsType',
            headerName: 'SMS Type',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'smsText',
            headerName: 'SMS Title',
            flex: 1,
            minWidth: 200
        },
        {
            field: 'customerName',
            headerName: 'Customer Name',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'sentBy',
            headerName: 'Sent By',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'sentDate',
            headerName: 'Sent Date',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'msgStatus',
            headerName: 'SMS Status',
            flex: 1,
            minWidth: 100
        }
    ];

    const fetchSendSmsList = async (data) => {
        const body = _.cloneDeep(data);
        if (body?.search?.msgStatus?.value) {
            body.search.msgStatus = body?.search?.msgStatus?.value;
        }
        const payload = createPayload(body);
        return getSendSmsListRequest(payload);
    };

    return (
        <>
            <Breadcrumb title='SMS History' />
            <Grid container justifyContent='center'>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ListProvider fetcher={fetchSendSmsList} initialData={initialData}>
                                {(listProps) => (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent='space-between'>
                                                <Grid item xs={12} sm={7} md={6} lg={5} xl={4}>
                                                    <SelectFilter
                                                        name='msgStatus'
                                                        label='Status'
                                                        options={smsStatusOptions}
                                                        listProps={listProps}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={5} md={6}>
                                                    {/* <Box */}
                                                    {/*    display="flex" */}
                                                    {/*    justifyContent="flex-end" */}
                                                    {/*    sx={{ */}
                                                    {/*        justifyContent: { */}
                                                    {/*            xs: 'center', */}
                                                    {/*            md: 'flex-end' */}
                                                    {/*        } */}
                                                    {/*    }} */}
                                                    {/* > */}
                                                    {/*    <Button */}
                                                    {/*        className={classes.button} */}
                                                    {/*        variant="outlined" */}
                                                    {/*        color="primary" */}
                                                    {/*        sx={{ */}
                                                    {/*            mr: 1 */}
                                                    {/*        }} */}
                                                    {/*        onClick={() => navigate(appUrls.SMS_TEMPLATE_ADD)} */}
                                                    {/*        disableElevation */}
                                                    {/*        startIcon={<AddIcon />} */}
                                                    {/*    > */}
                                                    {/*        Add SMS Template */}
                                                    {/*    </Button> */}
                                                    {/* </Box> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                p={1}
                                                style={{
                                                    // height: 500,
                                                    width: '100%'
                                                }}
                                            >
                                                <Box className={classes.progressBarContainer}>
                                                    {listProps.loading && <LinearProgress />}
                                                </Box>
                                                {listProps.listItems && !listProps.loading && (
                                                    <>
                                                        <DataGrid
                                                            rows={listProps.listItems}
                                                            columns={columns}
                                                            // pagination={false}
                                                            paginationMode='server'
                                                            pageSize={listProps.limit}
                                                            page={listProps.page}
                                                            row
                                                            autoHeight
                                                            disableColumnMenu
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                mt: 2,
                                                                mb: 0,
                                                                pb: 0
                                                            }}
                                                        >
                                                            <Pagination
                                                                color='primary'
                                                                count={listProps.totalPages}
                                                                page={listProps.page}
                                                                onChange={(e, value) => {
                                                                    console.log('-> value', value);

                                                                    listProps.setPage(value);
                                                                }}
                                                            />
                                                        </Box>
                                                    </>
                                                )}
                                                {listProps.initialLoading && (
                                                    <Grid container spacing={1}>
                                                        {[...Array(12)].map((_, i) => (
                                                            <Grid key={i} item xs={12}>
                                                                <Skeleton variant='rect' height={40} />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </ListProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default SendSmsListPage;

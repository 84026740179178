import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import SwitchField from '../../libs/formik_fields/SwitchField';
import appUrls from '../../appUrls';
import SelectField from '../../libs/formik_fields/SelectField';
import useFetchSelectOptions from '../../hooks/useFetchSelectOptions';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import { getActiveVehicleList, getActiveVehicleModalListByVehicle } from '../../services/vehicle';
import { customerDetailsByVehicleNo, searchCustomerByContactNo, searchCustomerByVehicleNo } from '../../services/job_card';
import AsyncCreatableSelectField from '../../libs/formik_fields/AsyncCreatableSelectField';
import { useSelector } from 'react-redux';
import { selectFormattedServiceList } from '../../store/slices/serviceSlice';
import DateTimeInputField from '../../libs/formik_fields/DateTimeInputField';
import { jobCardFormServiceKeysMap } from '../../services/service_setup';
import moment from 'moment';

const emptyJobCard = {
    jobCardNo: null,
    customer: {
        name: '',
        email: '',
        mobile: '',

        address: '',
        gstin: '',
        vehicleRegNo: '',
        vehicleDTO: null,
        vehicleModelDTO: null
    },
    odoMeterReading: null,
    remark: '',
    tyreTubeFitment: {
        enable: false
    },
    wheelAlignment: {
        enable: false
    },
    n2: {
        enable: false
    },
    tyreRepair: {
        enable: false
    },
    wheelBalancing: {
        enable: false
    },
    wheelBalancingWeight: null,
    otherProductSales: null,
    newTyreFitmentDTO: null,
    wheelTypeDTO: null,
    jobCardStatusDTO: null,
    jobCardCreateDateTime: moment().format('DD-MM-YYYY hh:mm A')
};

const tyreBrandSchema = yup.object().shape({
    address: yup.string('Please enter valid description.'),
    customer: yup.object().shape({
        name: yup.string().required('Please enter name of customer.'),
        address: yup.string('Please enter valid description.'),
        email: yup.string('Please enter valid email.'),
        gstin: yup.string('Please enter valid gstin.'),
        mobile: yup.mixed().required('Please enter mobile.'),
        vehicleRegNo: yup.mixed().required('Please enter vehicle registration number.'),
        vehicleDTO: yup.mixed().required('Vehicle is required.'),
        vehicleModelDTO: yup.mixed().required('Vehicle model is required.')
    }),
    remark: yup.string('Please enter valid remarks.').nullable()
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const JobCardFormContent = ({ isSubmitting, setFieldValue, values, errors, submitForm }) => {
    const [jobCardPayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.JOB_CARD });
    console.log('-> values', values);

    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(appUrls.JOB_CARD);
    }, [navigate]);

    const classes = useStyles();

    const { options: activeVehicleOptions, loading: activeVehiclesOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = jobCardPayloadCreator({});
            return getActiveVehicleList(payload);
        }
    });

    const [vehicleModals, setVehicleModals] = useState([]);
    const [vehicleModalsLoading, setVehicleModalsLoading] = useState(false);

    const selectedVehicleId = useMemo(() => values?.customer?.vehicleDTO?.id, [values?.customer?.vehicleDTO?.id]);

    const servicesList = useSelector(selectFormattedServiceList);
    const jobCardFormServiceList = useMemo(() => servicesList.filter((item) => item.status && item.showInAddJobCard), [servicesList]);
    useEffect(() => {
        async function fetchVehicleModals(vehicleId) {
            setVehicleModalsLoading(true);
            const payload = jobCardPayloadCreator({
                id: vehicleId
            });
            const result = await getActiveVehicleModalListByVehicle(payload);
            if (result.error === false) {
                setVehicleModals(result);
                const currentSelectedVehicleModalId = values.customer?.vehicleModelDTO?.id;
                if (currentSelectedVehicleModalId) {
                    const isCurrentSelectedModalValid = result.find((item) => item.id === currentSelectedVehicleModalId);
                    if (!isCurrentSelectedModalValid) {
                        setFieldValue('customer.vehicleModelDTO', null);
                    }
                } else {
                    setFieldValue('customer.vehicleModelDTO', null);
                }
            }
            setVehicleModalsLoading(false);
        }

        if (selectedVehicleId) {
            fetchVehicleModals(selectedVehicleId);
        }
    }, [selectedVehicleId]);

    // useEffect(() => {
    //     async function fetchCustomerList() {
    //         const payload = jobCardPayloadCreator({
    //             vehicleRegNo: values.vehicleRegNo
    //         });
    //         const result = await searchCustomerByVehicleNo(payload);
    //         if (result.error === false) {
    //             const { error, ...customersObj } = result;
    //             return Object.keys(customersObj).map((key) => ({
    //                 value: key,
    //                 label: customersObj[key]
    //             }));
    //         }
    //         return [];
    //     }
    //     if (values.vehicleRegNo) {
    //         fetchCustomerList();
    //     }
    // }, [values.vehicleRegNo]);

    useEffect(() => {
        async function fetchCustomerDetails() {
            const regNo = values?.customer?.vehicleRegNo?.value;
            const isNew = values?.customer?.vehicleRegNo?.isNew;
            if (regNo && !isNew) {
                const payload = jobCardPayloadCreator({
                    vehicleRegNo: regNo
                });
                const result = await customerDetailsByVehicleNo(payload);
                console.log('->customer details result from vehicleRegNo useEffect', result);
                if (result.error === false) {
                    const { customer } = result;
                    // const mobile = {
                    //     value: customer.mobile,
                    //     label: customer.mobile,
                    //     mobileNo: customer.mobile,
                    //     regNo: '',
                    //     isNew: true
                    // };
                    // setFieldValue('customer', { ...customer, vehicleRegNo: values?.customer?.vehicleRegNo, mobile });
                    setFieldValue('customer', { ...customer, vehicleRegNo: values?.customer?.vehicleRegNo });
                }
            }
        }

        fetchCustomerDetails();
    }, [values?.customer?.vehicleRegNo]);

    // useEffect(() => {
    //     async function fetchCustomerDetails() {
    //         const regNo = values?.customer?.mobile?.regNo;
    //         const isNew = values?.customer?.mobile?.isNew;
    //         if (regNo && !isNew) {
    //             const payload = jobCardPayloadCreator({
    //                 vehicleRegNo: regNo
    //             });
    //             const result = await customerDetailsByVehicleNo(payload);
    //             console.log('->customer details result from mobile useEffect', result);
    //             if (result.error === false) {
    //                 const { customer } = result;
    //                 const vehicleRegNo = {
    //                     value: customer.vehicleRegNo,
    //                     label: customer.vehicleRegNo,
    //                     isNew: true
    //                 };
    //                 setFieldValue('customer', { ...customer, mobile: values?.customer?.mobile, vehicleRegNo });
    //             }
    //         }
    //     }

    //     fetchCustomerDetails();
    // }, [values?.customer?.mobile]);

    const searchRegistrations = async (inputValue) => {
        if (typeof inputValue !== 'string') {
            return [];
        }

        const payload = jobCardPayloadCreator({
            vehicleRegNo: inputValue
        });
        const result = await searchCustomerByVehicleNo(payload);
        if (result.error === false) {
            const { error, ...customersObj } = result;
            const customers = Object.keys(customersObj).map((key) => ({
                value: key,
                label: customersObj[key]
            }));
            return customers;
        }
        return [];
    };

    const searchContactNo = async (inputValue) => {
        if (typeof inputValue !== 'string') {
            return [];
        }

        const payload = jobCardPayloadCreator({
            mobile: inputValue
        });
        const result = await searchCustomerByContactNo(payload);
        if (result.error === false) {
            const { error, ...customersObj } = result;
            console.log('-> customersObj', customersObj);
            const customers = Object.keys(customersObj).map((key) => {
                const [regNo, mobileNo] = key.split('#');
                return {
                    value: mobileNo,
                    mobileNo,
                    regNo,
                    label: `${customersObj[key]} ${mobileNo}`
                };
            });
            return customers;
        }
        return [];
    };
    console.log('-> errors', errors);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <AsyncCreatableSelectField
                    name="customer.vehicleRegNo"
                    placeholder="Registration*"
                    valueKey="value"
                    labelKey="label"
                    loadOptions={searchRegistrations}
                    onCreateOption={(inputValue) => {
                        setFieldValue('customer.vehicleRegNo', {
                            value: inputValue,
                            label: inputValue,
                            isNew: true
                        });
                    }}
                />
                <Grid container>
                    <Grid item xs={12} md={6} pr={1}>
                        {activeVehiclesOptionsLoading ? (
                            'Loading'
                        ) : (
                            <SelectField
                                name="customer.vehicleDTO"
                                placeholder="Vehicle*"
                                valueKey="id"
                                labelKey="name"
                                options={activeVehicleOptions}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6} pl={1}>
                        {vehicleModalsLoading ? (
                            'Loading'
                        ) : (
                            <SelectField
                                name="customer.vehicleModelDTO"
                                placeholder="Vehicle Model*"
                                valueKey="id"
                                labelKey="name"
                                options={vehicleModals}
                            />
                        )}
                    </Grid>
                </Grid>
                <TextField name="customer.name" label="Customer Name" />
                <TextField name="customer.gstin" label="GSTN" />
                <TextField name="customer.email" label="Email" />
                <TextField name="jobCardCreateDateTime" label="Job Creation Time" disabled />

                {/* eslint-disable-next-line react/destructuring-assignment */}
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField name="customer.mobile" label="Contact Number" />
                {/* <AsyncCreatableSelectField
                    name="customer.mobile"
                    placeholder="Contact Number*"
                    valueKey="value"
                    labelKey="label"
                    loadOptions={searchContactNo}
                    onCreateOption={(inputValue) => {
                        setFieldValue('customer.mobile', {
                            value: inputValue,
                            label: inputValue,
                            mobileNo: inputValue,
                            regNo: '',
                            isNew: true
                        });
                    }}
                /> */}
                <TextField name="customer.address" label="Address" multiline rows={4.1} />
                <TextField name="remark" label="Remarks" multiline rows={4.1} />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h3" sx={{ mb: 2 }}>
                            Tasks
                        </Typography>
                    </Grid>
                    {jobCardFormServiceList.map((service) => (
                        <Grid item xs={12} md={3} lg={2}>
                            <SwitchField name={`${jobCardFormServiceKeysMap[service.name]}.enable`} label={service.label} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                    onClick={() => {
                        setFieldValue('printPdf', true);
                        submitForm();
                    }}
                    sx={{
                        mx: 1
                    }}
                >
                    Save & Print
                </LoadingButton>
                <Button variant="outlined" color="secondary" onClick={handleBack}>
                    Back
                </Button>
            </Grid>
        </Grid>
    );
};

function JobCardForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyJobCard}
            onSubmit={handleSubmit}
            validationSchema={tyreBrandSchema}
            FormContent={JobCardFormContent}
        />
    );
}

export default JobCardForm;

// assets
import { IconDashboard, IconBuilding, IconBook, IconQuestionMark, IconUsers, IconSettings, IconLogout } from '@tabler/icons';
import { BarChart } from '@mui/icons-material';
import appUrls from '../appUrls';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const main = {
    id: 'main-routes',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: appUrls.DASHBOARD,
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'institute',
            title: 'Institute',
            type: 'item',
            url: appUrls.INSTITUTE_LIST,
            icon: IconBuilding,
            breadcrumbs: false
        },
        {
            id: 'yearbook',
            title: 'Yearbook',
            type: 'item',
            url: appUrls.YEAR_BOOK_LIST,
            icon: IconBook,
            breadcrumbs: false
        },
        {
            id: 'flipbook',
            title: 'FlipBooks',
            type: 'item',
            url: appUrls.FLIPBOOK_ADD,
            icon: IconBook,
            breadcrumbs: false
        },
        {
            id: 'profile_question',
            title: 'Profile Question',
            type: 'item',
            url: appUrls.PROFILE_QUESTION_LIST,
            icon: IconQuestionMark,
            breadcrumbs: false
        },
        {
            id: 'poll_question',
            title: 'Poll Question',
            type: 'item',
            url: appUrls.POLL_QUESTION_LIST,
            icon: BarChart,
            breadcrumbs: false
        },
        {
            id: 'students',
            title: 'Students',
            type: 'item',
            url: appUrls.STUDENT_LIST,
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'change_password',
            title: 'Change Password',
            type: 'item',
            url: appUrls.CHANGE_PASSWORD,
            icon: IconSettings,
            breadcrumbs: false
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: appUrls.LOGOUT,
            icon: IconLogout,
            breadcrumbs: false
        }
    ]
};

export default main;

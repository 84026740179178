import React, { useMemo } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CalendarViewDay, CalendarToday } from '@mui/icons-material';

function DateFilter({ listProps, name, label }) {
    const maxDate = useMemo(() => {
        if (name === 'from') {
            if (listProps?.search?.to) {
                return listProps?.search?.to;
            }
        }
        return undefined;
    }, [name, listProps?.search?.to]);

    const minDate = useMemo(() => {
        if (name === 'to') {
            if (listProps?.search?.from) {
                return listProps?.search?.from;
            }
        }
        return undefined;
    }, [name, listProps?.search?.from]);
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
                id={name}
                renderInput={(props) => (
                    <TextField
                        size='small'
                        {...props}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {' '}
                                    <CalendarToday />
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                label={label}
                onChange={(value) =>
                    listProps.setSearch({
                        [name]: value
                    })
                }
                inputFormat='dd-MM-yyyy'
                // disableFuture
                value={listProps?.search?.[name]}
                maxDate={maxDate}
                minDate={minDate}
            />
        </LocalizationProvider>
    );
}

export default DateFilter;

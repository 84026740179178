import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthorizedModules } from '../store/slices/authSlice';
import { useLocation } from 'react-router-dom';

function useIsAuthorized() {
    const authorizedModules = useSelector(selectAuthorizedModules);
    const location = useLocation();
    const firstPath = location.pathname.split('/')[1];
    return authorizedModules.includes(firstPath);
}

export default useIsAuthorized;
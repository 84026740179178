import React from 'react';
import useModalState from '../../hooks/useModalState';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { History as CommentHistoryIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import QuotationCommentHistoryList from './QuotationCommentHistoryList';


const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function QuotationCommentHistoryModal({ quotationId }) {
    const { isOpen, handleClose, handleOpen } = useModalState(false);

    return (
        <>
            <IconButton
                color='info'
                size='small'
                style={{ marginLeft: 16 }}
                onClick={handleOpen}
            >
                <CommentHistoryIcon />
            </IconButton>
            <Dialog open={isOpen} onClose={handleClose} maxWidth='xl' fullWidth>
                <DialogTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='h3'>Comment History</Typography>
                        <Button onClick={handleClose}>Close</Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid container>{isOpen && <QuotationCommentHistoryList quotationId={quotationId} />}</Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default QuotationCommentHistoryModal;
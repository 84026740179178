import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

export const getSendSmsListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/sendsms/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getActiveSendSmsListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/sendsms/activesms`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const smsStatusOptions = [
    {
        value: 'PENDING',
        label: 'Pending'
    },
    {
        value: 'SENT',
        label: 'Sent'
    },
    {
        value: 'FAILED',
        label: 'Failed'
    }
];

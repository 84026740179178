import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import useModalState from '../../hooks/useModalState';
import RateListForm from './RateListForm';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};

function RateListModal({ buttonText = 'Edit Rate List', rateList, onSubmit }) {
    const { isOpen, handleClose, handleOpen } = useModalState(false);
    return (
        <>
            <Button onClick={handleOpen} variant="outlined" color="primary">
                {buttonText}
            </Button>
            <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
                <DialogTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h3">Edit Rate List</Typography>
                        <Button onClick={handleClose}>Close</Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <RateListForm
                        rateListItems={rateList}
                        onSubmit={(...args) => {
                            if (onSubmit) {
                                onSubmit(...args);
                            }
                            handleClose();
                        }}
                    />
                </DialogContent>
            </Dialog>
            {/* <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"> */}
            {/*    <Box sx={style}> */}
            {/*        <Card> */}
            {/*            <CardContent> */}
            {/*                <RateListForm/> */}
            {/*            </CardContent> */}
            {/*        </Card> */}
            {/*    </Box> */}

            {/* </Modal> */}
        </>
    );
}

export default RateListModal;

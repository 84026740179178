import { store } from '../store';
import axios from 'axios';
import { extractDataFromResponse, parseApiErrorResponse } from 'utils/apiUtils';
import { refreshTokenRequest } from 'services/refresh_token';
import { toast } from 'react-toastify';

// eslint-disable-next-line import/prefer-default-export
export const callApi = async ({
    requestFunction,
    successCode = 200,
    showToastOnSuccess = true,
    showToastOnError = true,
    callRefreshTokenOnAuthError = true,
    authErrorCode = 401,
    useAuth = true,
    checkResponseData = true,
}) => {
    let axiosInstance;
    if (useAuth) {
        const accessToken = store?.getState()?.tokens?.accessToken;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        };
        axiosInstance = axios.create({
            headers
        });
    } else {
        axiosInstance = axios;
    }

    if (requestFunction) {
        try {
            const response = await requestFunction(axiosInstance);
            if(checkResponseData){
                return extractDataFromResponse({
                    response,
                    successCode,
                    showSuccessToast: showToastOnSuccess,
                    showErrorToast: showToastOnError
                });
            }
            if(response.status === successCode){
                return response;
            }
            return { error: true };

        } catch (error) {
            if (error.response) {
                if (error.response.status === authErrorCode || error.response.data.code === authErrorCode) {
                    if (callRefreshTokenOnAuthError) {
                        const refreshToken = store?.getState()?.tokens?.refreshToken;
                        const refreshTokenResponseData = await refreshTokenRequest({
                            refreshToken
                        });
                        if (refreshTokenResponseData.error) {
                            store.dispatch({
                                type: 'auth/logout/rejected',
                                payload: {}
                            });
                            return { error: true };
                        }
                        const newAccessToken = refreshTokenResponseData?.tokens?.access?.token;
                        const newRefreshToken = refreshTokenResponseData?.tokens?.refresh?.token;
                        if (newAccessToken && newRefreshToken) {
                            store.dispatch({
                                type: 'tokens/setTokens',
                                payload: {
                                    accessToken: newAccessToken,
                                    refreshToken: newRefreshToken
                                }
                            });
                            return callApi({
                                requestFunction,
                                successCode,
                                showToastOnSuccess,
                                showToastOnError,
                                callRefreshTokenOnAuthError: false
                            });
                        }
                        return { error: true };
                    }
                    store.dispatch({
                        type: 'auth/logout/rejected',
                        payload: {}
                    });
                    return { error: true };
                }

                return parseApiErrorResponse({
                    error,
                    showToast: showToastOnError
                });
            }
            if (showToastOnError) {
                toast.error('Something went wrong');
            }
            return { error: true };
        }
    }
    return { error: true };
};

import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import authSlice from './slices/authSlice';
import tokensSlice from 'store/slices/tokensSlice';
import serviceSlice from 'store/slices/serviceSlice';
import customizationReducer from './customizationReducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

export const persistConfig = {
    key: 'auth',
    storage
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice);
const persistedTokensReducer = persistReducer({ key: 'tokens', storage }, tokensSlice);

export const store = configureStore({
    reducer: {
        customization: customizationReducer,
        auth: persistedAuthReducer,
        tokens: persistedTokensReducer,
        service: serviceSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger)
});

export const persistor = persistStore(store);

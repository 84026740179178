import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import lodash from 'lodash';
import { useField } from 'formik';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import isEqual from 'react-fast-compare';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop:2,
        marginBottom: 4
        // marginBottom: theme.spacing(1),
    },
    error: {
        color: theme.palette.error.main,
        paddingLeft: theme.spacing(2)
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const SelectInput = React.memo((props) => <Select {...props} />, isEqual);

export const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 1000
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: 12,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 8,
        paddingRight: 8,
        background: 'none'
    })
};

export const narrowSelectStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 1000
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: 12,

        paddingLeft: 8,
        paddingRight: 8,
        background: 'none',
        minHeight: 10
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0
    })
};

function SelectField({ label = '', labelKey = 'name', valueKey = '_id', controlled = false, showErrorText = true, narrow, ...props }) {
    const classes = useStyles();
    const [{ value, ...field }, meta, helpers] = useField(props);
    const [fieldValue, setFieldValue] = useState(value);

    const styles = useMemo(() => (narrow ? narrowSelectStyles : selectStyles), [narrow]);

    useEffect(() => {
        console.log(value);
        setFieldValue(value);
    }, [value]);

    const handleSelectChange = useCallback(helpers.setValue, []);

    return (
        <Box className={classes.container} {...props}>
            <label htmlFor={props.name}>
                <Typography variant="body1">{label}</Typography>
            </label>
            <SelectInput
                id={props.name}
                styles={styles}
                {...field}
                onChange={handleSelectChange}
                // defaultValue={field.value ?? null}
                // value={field.value ?? null}
                getOptionLabel={(option) => option[labelKey]}
                getOptionValue={(option) => option[valueKey]}
                value={fieldValue}
                {...props}
            />
            {showErrorText && (
                <Typography className={classes.error} variant="caption">
                    {meta.touched && !!meta.error ? meta.error : '  '} &nbsp;
                </Typography>
            )}
        </Box>
    );
}

export default SelectField;

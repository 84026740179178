import React, { useMemo } from 'react';
import { selectAuthorizedModulesMap, selectUserSiteId } from '../store/slices/authSlice';
import { useSelector } from 'react-redux';

function useSiteModulePayloadCreator({ moduleName }) {
    const siteId = useSelector(selectUserSiteId);
    const authorizedModules = useSelector(selectAuthorizedModulesMap);
    const moduleId = useMemo(() => authorizedModules[moduleName], [authorizedModules,moduleName]);

    const payloadCreator = (dataObj, extraData = {}) => ({
        menu: moduleId,
        site: siteId,
        data: dataObj,
        ...extraData
    });

    return [payloadCreator];
}

export default useSiteModulePayloadCreator;

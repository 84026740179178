import React, { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import lodash from 'lodash';
import { useField } from 'formik';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop:2,
        marginBottom: 4
        // marginBottom: theme.spacing(1),
    },
    error: {
        color: theme.palette.error.main,
        paddingLeft: theme.spacing(2)
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const AsyncCreatableSelectInput = React.memo((props) => <AsyncCreatableSelect {...props} />, lodash.isEqual);

export const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 1000
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: 12,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 8,
        paddingRight: 8,
        background: 'none'
    })
};

function AsyncCreatableSelectField({ label = '', labelKey = 'name', valueKey = '_id', ...props }) {
    const classes = useStyles();
    const [{ value, ...field }, meta, helpers] = useField(props);
    const [fieldValue, setFieldValue] = useState(value);

    useEffect(()=>{
        setFieldValue(value);
    },[value])

    return (
        <div className={classes.container}>
            <label htmlFor={props.name}>
                <Typography variant="body2">{label}</Typography>
            </label>
            <AsyncCreatableSelectInput
                id={props.name}
                styles={selectStyles}
                {...field}
                onChange={helpers.setValue}
                // defaultValue={field.value ?? null}
                // value={field.value ?? null}
                getOptionLabel={(option) => option[labelKey]}
                getOptionValue={(option) => option[valueKey]}
                value={fieldValue}
                defaultOptions
                {...props}
            />
            <Typography className={classes.error} variant="caption">{meta.touched && !!meta.error ? meta.error : '  '} &nbsp;</Typography>
        </div>
    );
}

export default AsyncCreatableSelectField;

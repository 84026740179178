import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import VehicleForm from 'components/vehicle/VehicleForm';
import { saveVehicleRequest } from 'services/vehicle';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddVehiclePage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [vehiclePayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.VEHICLE });

    const navigate = useNavigate();

    const handleAddVehicle = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { name, description, status, model } = values;
        const data = {
            name,
            description,
            status: {
                id: status
            },
            model: model.map((item) => ({
                name: item.name,
                description: item.description,
                status: { id: item.status },
                rate: item.rate,
            }))
        };
        const payload = vehiclePayloadCreator(data);
        const response = await saveVehicleRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.VEHICLE);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Add Vehicle" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <VehicleForm handleSubmit={handleAddVehicle} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddVehiclePage;

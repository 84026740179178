import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export
export const saveIncentiveEntryRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/save`, payload),
        successCode: 200
    });

export const getIncentiveEntryListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getIncentiveEntryExcelRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/excel/export`, payload, { responseType: 'blob' }),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true,
        checkResponseData: false
    });

export const getIncentiveEntryTotalRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/total`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true,
        checkResponseData: false
    });

export const fetchLateFollowupCountRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/latefollowup`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true,
        checkResponseData: false
    });

export const getIncentiveEntryDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/get`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getDetailsByJobcardRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/getbyjobcardnumber`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const deleteIncentiveEntryRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/delete`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const incentiveEntryTotalRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/total`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });
export const getActiveUsersListForIncentiveEntry = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/user/operator/listrestricted`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });
export const incentiveEntrySegmentOptionsList = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/incentiveentry/segment`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

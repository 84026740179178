import { callApi } from '../utils/callApi';
import { API_BASE_URL } from './index';

export const complaintSegmentOptionsList = [
    { id: '1', segment: 'Car Tyre' },
    { id: '2', segment: 'Two Wheeler' },
    { id: '3', segment: 'Three Wheeler' },
    { id: '4', segment: 'LCV' },
    { id: '5', segment: 'SCV' },
    { id: '6', segment: 'HCV' },
    { id: '7', segment: 'OTR' }
];

export const complaintStatusOptionsList = [
    { id: '1', value: 'Pending' },
    { id: '2', value: 'Resolved' },
    { id: '3', value: 'Call Made' },
    { id: '4', value: 'Deleted' }
];

// eslint-disable-next-line import/prefer-default-export
export const getComplaintsListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/complaint/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getComplaintDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/complaint/get`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getComplaintCommentHistoryRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/complaint/comment/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const saveComplaintRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/complaint/save`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });

export const getComplaintExcelRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/complaint/excel/export`, payload, { responseType: 'blob' }),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true,
        checkResponseData: false
    });

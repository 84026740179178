const appUrls = {
    LOGIN: '/login',
    LOGOUT: '/logout',
    CHANGE_PASSWORD: '/change-password',

    HOME: '/',
    DASHBOARD: '/dashboard',
    // ADMINISTRATOR ROUTES
    ADMIN_DASHBOARD: '/admindashboard',
    TAX_SLAB: '/taxslab',
    BILL_TEMPLATE: '/billtemplate',
    SERVICE_SETUP: '/servicesetup',
    INCENTIVE_TARGET: '/incentivetarget',
    OPERATOR_MANAGEMENT: '/operatormgmt',
    OPERATOR_MANAGEMENT_ADD: '/operatormgmt/add',
    OPERATOR_MANAGEMENT_EDIT: '/operatormgmt/edit',
    SMS_AND_EMAIL_SETTINGS: '/tpapi',
    QUOTATION_TEMPLATE: '/qotationtemplate',

    // MASTER ROUTES
    TYRE_BRAND: '/tyrebrand',
    TYRE_BRAND_ADD: '/tyrebrand/add',
    TYRE_BRAND_EDIT: '/tyrebrand/edit',
    TYRE: '/tyres',
    TYRE_ADD: '/tyres/add',
    TYRE_EDIT: '/tyres/edit',
    TYRE_SIZE: '/tyresize',
    TYRE_SIZE_ADD: '/tyresize/add',
    TYRE_SIZE_EDIT: '/tyresize/edit',
    RATE_LIST: '/ratelist',
    VEHICLE: '/vehicle',
    VEHICLE_ADD: '/vehicle/add',
    VEHICLE_EDIT: '/vehicle/edit',

    JOB_CARD: '/jobcard',
    JOB_CARD_ADD: '/jobcard/add',
    JOB_CARD_EDIT: '/jobcard/edit',
    JOB_CARD_PROCESS: '/jobcard/process',
    BILLING: '/billing',
    COMPLAINT: '/complaint',
    COMPLAINT_ADD: '/complaint/add',
    COMPLAINT_EDIT: '/complaint/edit',
    COMPLAINT_COMMENT_HISTORY: '/complaint/comment-history',
    CUSTOMERS: '/customers',
    COMMERCIAL_CUSTOMERS: '/commercialcustomer',
    ORDER: '/order',
    ORDER_ADD: '/order/add',
    ORDER_EDIT: '/order/edit',

    QUOTATION_LIST: '/quotation',
    QUOTATION_ADD: '/quotation/add',
    QUOTATION_EDIT: '/quotation/edit',

    SMS_TEMPLATE_LIST: '/smstemplate',
    SMS_TEMPLATE_ADD: '/smstemplate/add',
    SMS_TEMPLATE_EDIT: '/smstemplate/edit',

    SEND_SMS: '/sendsms',

    INCENTIVE_SETUP_LIST: '/incentivesetup',
    INCENTIVE_SETUP_ADD: '/incentivesetup/add',
    INCENTIVE_SETUP_EDIT: '/incentivesetup/edit',

    INCENTIVE_ENTRY_LIST: '/incentiveentry',
    INCENTIVE_ENTRY_ADD: '/incentiveentry/add',
    INCENTIVE_ENTRY_EDIT: '/incentiveentry/edit',

    // REPORTS ROUTE
    REPORT_1: '/report1',
    REPORT_2: '/report2',
    REPORT_3: '/report3',
    REPORT_4: '/report4'
};

export default appUrls;

import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export
export const saveTyreSizeRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyresize/save`, payload),
        successCode: 200
    });

export const getTyreSizeListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyresize/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getTyreSizeDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyresize/get`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getActiveTyreSizeListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyresize/active/list`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export
export const saveVehicleRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/vehicle/save`, payload),
        successCode: 200
    });

export const getVehicleListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/vehicle/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getVehicleDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/vehicle/get`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });



export const getActiveVehicleList = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/vehicle/activelist`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getActiveVehicleModalListByVehicle = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/vehicle/activevehiclemodellistbyvehicle`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });
import React from 'react';
import { useField } from 'formik';
import { Button, ButtonGroup, FormGroup, Typography } from '@mui/material';

export const statusEnum = {
    ACTIVE: 1,
    INACTIVE: 2,
    DELETED: 3
};

function StatusField({ label, center = false, ...props }) {
    const [{ value, ...field }, meta, helpers] = useField(props);
    return (
        <FormGroup
            sx={{
                pl: 0.5,

                display: 'flex'
            }}
        >
            {label && <Typography variant="body">{label}</Typography>}
            <ButtonGroup
                size='small'
                aria-label='small button group'
                sx={{
                    justifyContent: center ? 'center' : 'flex-start'
                }}
            >
                <Button
                    variant={value === statusEnum.ACTIVE ? 'contained' : 'outlined'}
                    color='success'
                    key='active'
                    onClick={() => {
                        helpers.setValue(statusEnum.ACTIVE);
                    }}
                >
                    Active
                </Button>
                <Button
                    variant={value === statusEnum.INACTIVE ? 'contained' : 'outlined'}
                    color='secondary'
                    key='inactive'
                    onClick={() => {
                        helpers.setValue(statusEnum.INACTIVE);
                    }}
                >
                    Inactive
                </Button>
                <Button
                    variant={value === statusEnum.DELETED ? 'contained' : 'outlined'}
                    color='error'
                    key='deleted'
                    onClick={() => {
                        helpers.setValue(statusEnum.DELETED);
                    }}
                >
                    Deleted
                </Button>
            </ButtonGroup>
        </FormGroup>
    );
}

export default StatusField;
